import React, { Component } from 'react';
import { fromJS, Map, List, is } from 'immutable';
import { WMImage, LevelTag, NumberInput, Button, _, WMkit, history } from 'wmkit';
import { MarketingLabel } from 'biz';
import {
  createImmutableData,
  calculateSpeInfo,
  changeSpecDetail,
  returnStockFlag,
  returnStock,
  changeNum,
  purchase
} from './state-change';
import { msg } from 'plume2';

const SubmitButton = Button.Submit;
const styles = require('../css/style.css');

/**
 * 批发销售类型的商品-规格选择弹框
 */
export default class WMWholesaleChoose extends Component<any, any> {
  props: {
    data: any;
    visible: boolean;
    changeSpecVisible: Function;
    skuReminder?: Function;
    addRegular?: Function;
    addEnquiry?: Function;
    enquiryList?: any;
    enquiryFlag?: boolean;
    innerFlag?: boolean; // 常购询价到货提醒是否在内部的flag
    goodsBuyTypes: any;
    _didConfirm: Function;
    remindList?: any;
    regularList?: any;
    cancelReminder?: Function;
    cancelRegular?: Function;
  };

  constructor(props) {
    super(props);
    this.state = {
      showIntervalFlag: false,
      selfIntervalPrices: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && nextProps.data.skuId) {
      // 组装层级结构的规格数据
      const dataIm = createImmutableData(nextProps.data);
      let otherProps = {};
      if (nextProps.remindList != undefined || nextProps.regularList != undefined) {
        otherProps['remindList'] = nextProps.remindList;
        otherProps['regularList'] = nextProps.regularList;
      }
      // 计算前n-1个规格与最后一个规格内容
      this.setState({
        ...dataIm,
        ...calculateSpeInfo(dataIm, otherProps)
      });
    }
  }

  render() {
    const {
      visible,
      changeSpecVisible,
      enquiryList,
      enquiryFlag,
      data,
      innerFlag,
      goodsBuyTypes
    } = this.props;
    const {
      minPrice = 0,
      maxPrice = 0,
      lineShowPrice = null,
      noSpecPrices = [],
      noSpecStockFlag = true,
      goods = Map(),
      goodsInfo = Map(),
      buyGoodsInfos = List(),
      images = List(),
      calGoodsSpecs = List()
    } = this.state;
    //下单方式
    const buyType = goodsBuyTypes ? goodsBuyTypes : 0;

    // 设价方式, 是否允许独立设价
    const priceType = goods.get('priceType');
    const allowPriceSet = goods.get('allowPriceSet');
    // 当前规格页已购买几件
    const goodsCount = buyGoodsInfos.reduce(
      (sumCount, info) => sumCount + info.get('buyCount'),
      0
    );
    const isAuthState = WMkit.isAuthState();
    return (
      <div style={{ display: !visible || !goods ? 'none' : 'block' }}>
        <div
          className="choose-mask"
          onClick={() => {
            changeSpecVisible(false);
          }}
        />
        <div className="choose-body">
          <div className="choose-content">
            {/*弹窗关闭按钮*/}
            <div
              className="close"
              onClick={() => {
                changeSpecVisible(false);
              }}
            >
              <i className="iconfont icon-guanbi" />
            </div>

            {/*sku图文信息*/}
            <div className="choose-top b-1px-b">
              <WMImage
                mode="pad"
                src={images.get(0)}
                alt=""
                width="100"
                height="100"
              />
              <div className="title-box">
                <p className="title">{goods ? goods.get('goodsName') : ' '}</p>
                {/* 阶梯价 且 (不允许独立设价 或 无规格商品)*/}
                {WMkit.isLogin() && WMkit.isAuthState() != 1 &&
                  <p className="price">
                    {'¥' + _.addZero(goodsInfo.get('marketPrice'))}
                  &nbsp;
                </p>
                }
                {WMkit.isLogin() && WMkit.isAuthState() == 1 &&
                  priceType == 1 &&
                  (allowPriceSet == 0 ||
                    (!calGoodsSpecs || calGoodsSpecs.size == 0)) ? (
                    <div className="step-price-box">
                      {this._showSpuIntervalPrices() &&
                        this._showSpuIntervalPrices().map((inter) => {
                          return (
                            <div className="step-box" key={inter.id}>
                              <span className="price">¥{inter.price}</span>
                              {/*<span className="delPrice">¥132.00</span>*/}
                              <span className="numText">
                                ≥&nbsp;{inter.count}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    WMkit.isLogin() && WMkit.isAuthState() == 1 && (
                      <p className="price">
                        {minPrice != 0 &&
                          (minPrice == maxPrice
                            ? '¥' + _.addZero(minPrice)
                            : '¥' +
                            _.addZero(minPrice) +
                            '~¥' +
                            _.addZero(maxPrice))}
                      &nbsp;
                      </p>
                    )
                  )}
                {!WMkit.isLogin() &&
                  (<a href="/login">
                    <p className="redTitle">价格登录可见</p>
                  </a>)
                }
                <div className="level-box">
                  {/*若无规格时,在此处展示促销标签*/}
                  {(!calGoodsSpecs || calGoodsSpecs.size == 0) && (
                    <MarketingLabel
                      marketingLabels={goodsInfo.get('marketingLabels')}
                      couponLabels={goodsInfo.get('couponLabels')}
                    />
                  )}
                  {priceType == 1 && <LevelTag text="阶梯价" />}
                  {/*
                    因为spu与sku可能设价不一致等问题,暂时不展示会员权益
                    <LevelTag text="SVIP8.5折" />
                  */}
                </div>
              </div>
            </div>

            {/*sku中间滚动区域*/}
            <div className="choose-center">
              {/*sku选择规格*/}
              {calGoodsSpecs && calGoodsSpecs.size > 0 ? (
                calGoodsSpecs.toJS().map((spec, index) => {
                  // 最后一个规格,展开展示
                  if (index == calGoodsSpecs.size - 1) {
                    spec.specDetails.map((item) => {
                      let filter =
                        enquiryList &&
                        enquiryList.find(
                          (it) => item.goodsInfoId === it.goodsInfoId
                        );
                      //根据goodsinfoID 判断包装信息字段显示
                      let filert2 = data.goodsInfos.filter((it) => it.goodsInfoId === item.goodsInfoId)[0];
                      if (filert2) {
                        item.erpPackaging = filert2.erpPackaging;
                      }

                      if (filter) {
                        item.enquiry = true;
                      } else {
                        item.enquiry = false;
                      }
                    });
                    spec.specDetails.map((item) => {
                      let filter2 = data.goodsInfos.find(
                        (its) => item.goodsInfoId === its.goodsInfoId
                      );
                      if (filter2) {
                        item.warningStock = filter2.warningStock;
                        item.marketPrice = filter2.marketPrice;
                      } else {
                        item.warningStock = 0;
                      }
                    });
                    return (
                      <div key={spec.specId}>
                        <p className="sku-title">{spec.specName}</p>
                        {spec.specDetails.map((det) => {
                          const noStock = returnStockFlag(
                            det.stock,
                            det.minCount,
                            det.warningStock
                          );
                          return (
                            <div className="sku-num" key={det.specDetailId}>
                              <div className="sku-top">
                                {noStock ?
                                  (<span className="sku-name-no-stock">
                                    {det.detailName}
                                  </span>) : (<span className="sku-name">
                                    {det.detailName}
                                  </span>)}
                                <span className="other-text">
                                  {det.minCount && det.minCount > 0 ? '起订' + det.minCount : null}
                                  {det.maxCount && det.maxCount > 0 && '，' ? '可购' + det.maxCount : null}
                                  {WMkit.isLogin() && innerFlag && (
                                    <img
                                      style={{ width: 18 }}
                                      src={det.regularFlag ? require('../../../images/regular-on.png') : require('../../../images/regular-off.png')}
                                      onClick={() => {
                                        if (det.regularFlag) {
                                          // 取消常购
                                          this.cancelRegular(det.goodsInfoId);
                                        } else {
                                          this.addRegular(det.goodsInfoId);
                                        }
                                      }
                                      }
                                    />
                                  )}
                                  &nbsp;&nbsp;
                                  {noStock && WMkit.isLogin() && innerFlag && (
                                    <img
                                      style={{ width: 18 }}
                                      src={det.remindFlag ? require('../../../images/remind-on.png') : require('../../../images/remind-off.png')}
                                      onClick={() => {
                                        if (det.remindFlag) {
                                          // 取消到货提醒
                                          this.cancelReminder(det.goodsInfoId);
                                        } else {
                                          this.skuReminder(det.goodsInfoId);
                                        }
                                      }
                                      }
                                    />
                                  )}
                                  &nbsp;&nbsp;
                                  {WMkit.isLogin() &&
                                    innerFlag &&
                                    (det.price == 0 || det.price == 0.0) && (
                                      <img
                                        style={{ width: 18 }}
                                        src={
                                          det.enquiry
                                            ? require('../../../images/enquiry-off.png')
                                            : require('../../../images/enquiry-on.png')
                                        }
                                        onClick={() =>
                                          !det.enquiry &&
                                          this.addEnquiry(
                                            fromJS({
                                              goodsId: det.goodsId,
                                              storeId: goods.get('storeId'),
                                              goodsInfoId: det.goodsInfoId
                                            })
                                          )
                                        }
                                      />
                                    )}
                                  &nbsp;&nbsp;
                                  {returnStock(goods, det) > 0
                                    ? '库存充足'
                                    : '缺货'}
                                </span>
                              </div>
                              <div className="sku-bottom">
                                <div className="unit">
                                  单位：{goods.get('goodsUnit')}&nbsp;&nbsp;{det.erpPackaging}
                                </div>

                                <NumberInput
                                  disableNumberInput={
                                    WMkit.isLogin() &&
                                      (det.price == 0 || det.price == 0.0)
                                      ? true
                                      : det.price == 0 || det.price == 0.0
                                        ? true
                                        : noStock
                                  }
                                  value={noStock ? 0 : det.num}
                                  max={returnStock(goods, det) || 0}
                                  onDelayChange={(value) =>
                                    this._changeNum(
                                      value,
                                      det.stock,
                                      det.goodsInfoId,
                                      det.intervalPrices
                                    )
                                  }
                                  min={0}
                                  error={det.error}
                                />
                              </div>
                              {WMkit.isLogin() ? (
                                <div className="bottom-price-box">
                                  <span className="marketPrice">市场价：</span>
                                  <span className={isAuthState == 1 ? 'line-price marketPrice' : 'marketPrice'}>
                                    {/*¥ {_.addZero(goodsInfo.get('marketPrice'))}*/}
                                      ¥ {_.addZero(det.marketPrice)}
                                  </span>
                                  <span className="marketPrice">会员价：</span>
                                  {isAuthState != 1 ? (
                                    <a className="price"
                                      onClick={() => { history.push('/qualification-apply'); }}
                                    >
                                      <span>认证后专享</span>
                                    </a>)
                                    : (<span className="price">
                                      ¥ {_.addZero(det.price)}
                                    </span>)}
                                  <MarketingLabel
                                    marketingLabels={fromJS(
                                      det.marketingLabels
                                    )}
                                    couponLabels={fromJS(det.couponLabels)}
                                  />
                                  {/*订货量设价*/}
                                  {priceType == 1 && allowPriceSet == 1 && (
                                    <span
                                      className="showStep"
                                      onClick={() =>
                                        this._showSkuIntervalPrices(
                                          det.intervalPrices
                                        )
                                      }
                                    >
                                      &nbsp;&nbsp;阶梯价&gt;
                                    </span>
                                  )}
                                </div>
                              ) : (
                                  <div className="bottom-price-box">
                                    <a href="/login">
                                      <span className="price">价格登录可见</span>
                                    </a>
                                  </div>
                                )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  } else {
                    return (
                      <div className="spec-box" key={spec.specId}>
                        <label className="spec-title">{spec.specName}</label>
                        <div className="spec-item">
                          {spec.specDetails.map((det) => {
                            return (
                              <span
                                key={det.specDetailId}
                                onClick={
                                  spec.defaultVal != det.specDetailId &&
                                    det.disabled
                                    ? () => { }
                                    : () => {
                                      this._changeSpecDetail(
                                        spec.defaultVal == det.specDetailId
                                          ? null
                                          : det.specDetailId,
                                        index
                                      );
                                    }
                                }
                                className={
                                  spec.defaultVal == det.specDetailId
                                    ? 'spec-items checked'
                                    : det.disabled
                                      ? 'spec-items invalid'
                                      : 'spec-items'
                                }
                              >
                                {det.detailName}
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                  <div className="sku-num retail-num">
                    <div className="sku-bottom">
                      <label className="spec-title">数量</label>
                      <div className="row-flex">
                        <span className="other-text">
                          {goodsInfo.get('count') &&
                            goodsInfo.get('count') + '起订'}
                          {goodsInfo.get('maxCount') &&
                            '，' + '限订' + goodsInfo.get('maxCount')}
                        &nbsp;&nbsp;库存
                        {returnStock(goods, goodsInfo.toJS()) || 0}
                          {goods.get('goodsUnit')}&nbsp;&nbsp;
                      </span>
                        {/* {WMkit.isLogin() &&
                        goodsInfo.get('priceType') == 2 &&
                        goodsInfo.get('marketPrice') == 0 && (
                          <img
                            style={{ width: 18 }}
                            src={
                              enquiryFlag
                                ? require('../../../images/reminder.png')
                                : require('../../../images/tagbg.png')
                            }
                            onClick={() =>
                              !enquiryFlag &&
                              this.addEnquiry(
                                fromJS({
                                  goodsId: goodsInfo.get('goodsId'),
                                  storeId: goods.get('storeId'),
                                  goodsInfoId: goodsInfo.get('goodsInfoId')
                                })
                              )
                            }
                          />
                        )} */}
                        <NumberInput
                          disableNumberInput={
                            WMkit.isLogin() &&
                              goodsInfo.get('priceType') == 2 &&
                              goodsInfo.get('marketPrice') == 0
                              ? true
                              : noSpecStockFlag
                          }
                          value={noSpecStockFlag ? 0 : goodsInfo.get('num')}
                          max={returnStock(goods, goodsInfo.toJS()) || 0}
                          onDelayChange={(value) =>
                            this._changeNum(
                              value,
                              goodsInfo.get('stock'),
                              goodsInfo.get('goodsInfoId'),
                              noSpecPrices
                            )
                          }
                          min={0}
                          error={goodsInfo.get('error')}
                        />
                      </div>
                    </div>
                  </div>
                )}
            </div>

            {/*sku底部加入购物车*/}
            <div className="bottom b-1px-t">
              <span className="normal-text">
                已选{buyGoodsInfos.size}种&nbsp;
                {goodsCount}
                {goods.get('goodsUnit')}
              </span>
              {!calGoodsSpecs || calGoodsSpecs.size == 0 ? (
                <div className="bottom-right">
                  {WMkit.isLogin() && innerFlag && (
                    <SubmitButton
                      text="常购"
                      onClick={() =>
                        this.addRegular(goodsInfo.get('goodsInfoId'))
                      }
                      defaultStyle={{
                        // color: '#BD2D27',
                        borderColor: '#BD2D27'
                      }}
                    />
                  )}
                  {/* 询价按钮 */}
                  {WMkit.isLogin() &&
                    innerFlag &&
                    goodsInfo.get('priceType') == 2 &&
                    goodsInfo.get('marketPrice') == 0 && (
                      <SubmitButton
                        disabled={enquiryFlag}
                        text="询价"
                        onClick={() =>
                          this.addEnquiry(
                            fromJS({
                              goodsId: goodsInfo.get('goodsId'),
                              storeId: goods.get('storeId'),
                              goodsInfoId: goodsInfo.get('goodsInfoId')
                            })
                          )
                        }
                        defaultStyle={{
                          backgroundColor: '#BD2D27',
                          borderColor: '#BD2D27'
                        }}
                      />
                    )}
                  {/* 到货提醒按钮 */}
                  {WMkit.isLogin() && innerFlag && noSpecStockFlag && (
                    <SubmitButton
                      key={'reminder'}
                      defaultStyle={{
                        // height: 47,
                        // lineHeight: '47px',
                        backgroundColor: '#BD2D27',
                        borderColor: '#BD2D27'
                      }}
                      text="到货提醒"
                      onClick={() =>
                        this.skuReminder(goodsInfo.get('goodsInfoId'))
                      }
                    />
                  )}
                  {!noSpecStockFlag && (
                    <SubmitButton
                      defaultStyle={{
                        // height: 47,
                        // lineHeight: '47px',
                        backgroundColor: '#D7513D',
                        borderColor: '#D7513D'
                      }}
                      disabled={!buyGoodsInfos || buyGoodsInfos.size == 0}
                      text={buyType == 0 ? '加入购物车' : '立即购买'}
                      onClick={() => this._purchase(buyType)}
                    />
                  )}
                </div>
              ) : (
                  <SubmitButton
                    defaultStyle={{
                      // height: 47,
                      // lineHeight: '47px',
                      backgroundColor: '#D7513D',
                      borderColor: '#D7513D'
                    }}
                    disabled={!buyGoodsInfos || buyGoodsInfos.size == 0}
                    text={buyType == 0 ? '加入购物车' : '立即购买'}
                    onClick={() => this._purchase(buyType)}
                  />
                )}
            </div>

            {/*点击后价格后的阶梯价是否显示阶梯价弹窗*/}
            {this.state.showIntervalFlag && (
              <div>
                <div
                  className="choose-mask"
                  onClick={() => this.setState({ showIntervalFlag: false })}
                />
                <div className="choose-body">
                  <div className="step-body">
                    {this.state.selfIntervalPrices.map((inter) => {
                      return (
                        <div className="step-box" key={inter.id}>
                          <span className="price">¥{inter.price}</span>
                          {/*<span className="delPrice">¥132.00</span>*/}
                          <span className="numText">≥&nbsp;{inter.count}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  /**
   * 批发类型,订货量设价,不允许独立设价时展示spu统一的阶梯价格
   * @private
   */
  _showSpuIntervalPrices = () => {
    const { goodsIntervalPrices } = this.state;

    const prices =
      goodsIntervalPrices &&
      (goodsIntervalPrices
        .filter((pri) => pri.get('type') === 0)
        .map((interPri) => {
          return {
            id: interPri.get('intervalPriceId'),
            price: interPri.get('price'),
            count: interPri.get('count')
          } as any;
        })
        .sortBy((pri) => pri.count) as any);
    return prices;
  };

  /**
   * 显示阶梯价格弹框
   * @param intervalPrices 阶梯价
   * @private
   */
  _showSkuIntervalPrices = (selfIntervalPrices) => {
    this.setState({ showIntervalFlag: true, selfIntervalPrices });
  };

  /**
   * 切换选中前n-1个规格项的规格值
   * @param specDetailId
   * @param index
   * @private
   */
  _changeSpecDetail = (specDetailId, index) => {
    this.setState(changeSpecDetail(this.state, specDetailId, index));
  };

  /**
   * 用户改变sku购买数量
   * @param num 数量
   * @param stock 库存
   * @param goodsInfoId sku标识
   * @private
   */
  _changeNum = (savedNum, stock, goodsInfoId, prices) => {
    savedNum = stock > 0 ? (savedNum < stock ? savedNum : stock) : 0;

    //找到当前购买数量对应的价格
    let goodsPrices = null;
    let goodsPricesMin = prices[0].price;
    prices.forEach((pri) => {
      if (savedNum >= pri.count) {
        goodsPrices = pri.price;
      }
      if (pri.price >= goodsPricesMin) {
        goodsPricesMin = pri.price;
      }
    });
    //若未找到,默认取第一个值作为价格
    if (goodsPrices === null) {
      goodsPrices = goodsPricesMin;
    }
    this.setState(
      changeNum(this.state, { num: savedNum, price: goodsPrices, goodsInfoId })
    );
  };

  /**
   * 加入购物车
   * @private
   */
  _purchase = async (goodsBuyTypes) => {
    if (goodsBuyTypes == 0) {
      //加入购物车
      const result = await purchase(this.state);
      if (result) {
        // 成功返回后,关闭弹框
        this.setState(result);
        this.props.changeSpecVisible(false);
      }
    } else {
      if (!WMkit.isLogin()) {
        //立即购买
        history.push({
          pathname: '/login'
        });
      } else {
        this.props._didConfirm(this.state, 0);
      }
    }
  };

  /**
   * 批发类型的商品  sku 到货提醒
   */
  skuReminder = (goodsInfoId) => {
    const { goodsInfos, goods } = this.state;
    let remindGoodsInfo = goodsInfos.find(it => it.get('goodsInfoId') == goodsInfoId);
    this.props.skuReminder(goodsInfoId, remindGoodsInfo, goods);
  };

  /**
   * @param goodsInfoId 
   * 取消到货提醒
   */
  cancelReminder = (goodsInfoId) => {
    const { goodsInfos } = this.state;
    let remindGoodsInfo = goodsInfos.find(it => it.get('goodsInfoId') == goodsInfoId);
    this.props.cancelReminder(remindGoodsInfo);
  }

  /**
   * 批发类型商品  sku 加入常购清单
   */
  addRegular = (goodsInfoId) => {
    const { goodsInfos, goods } = this.state;
    const skuGoodsInfo = goodsInfos.find(it => it.get('goodsInfoId') == goodsInfoId);
    this.props.addRegular(goodsInfoId, skuGoodsInfo, goods);
  };

  /**
   * @param goodsInfoId 
   * 取消常购
   */
  cancelRegular = (goodsInfoId) => {
    const { goodsInfos } = this.state;
    let remindGoodsInfo = goodsInfos.find(it => it.get('goodsInfoId') == goodsInfoId);
    this.props.cancelRegular(remindGoodsInfo);
  }

  addEnquiry = (object) => {
    this.props.addEnquiry(object);
  };
}
