/**
 * 未登录时,对购物车的操作
 */
import { msg } from 'plume2';
import { Alert, Fetch, WMkit } from 'wmkit';
import { cache, Const, config } from 'config';

/**
 * sku追加到前端购物车缓存(非幂等)
 *   目前商品详情采用此方式
 * @author bail
 * @param goodsInfoId skuId
 * @param num sku数量
 * @returns {boolean} 是否成功加入前端购物车缓存
 */
const postPurchase = (goodsInfoId, goodsNum) => {
  try {
    // 1.查询商品是否已经在购物车缓存中
    const purStr = localStorage.getItem(WMkit.purchaseCache());
    let purArr = new Array();
    if (purStr) {
      purArr = JSON.parse(purStr) as Array<any>;
      const skuIndex = purArr.findIndex(
        (sku) => sku.goodsInfoId == goodsInfoId
      );
      if (skuIndex > -1) {
        // 1.1.若已经存在缓存中,则[累加]数量
        purArr[skuIndex].goodsNum = purArr[skuIndex].goodsNum + goodsNum;
        localStorage.setItem(WMkit.purchaseCache(), JSON.stringify(purArr));
        return true;
      }
    }
    // 1.2.若不在,则加入缓存中
    addInPurchase(purArr, goodsInfoId, goodsNum);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * 加入前端购物车缓存(幂等)
 *   目前商品列表采用此方式
 * @author bail
 * @param goodsInfoId skuId
 * @param num 追加的sku数量
 */
const putPurchase = (goodsInfoId, goodsNum) => {
  try {
    // 1.查询商品是否已经在购物车缓存中
    const purStr = localStorage.getItem(WMkit.purchaseCache());
    let purArr = new Array();
    if (purStr) {
      purArr = JSON.parse(purStr) as Array<any>;
      const skuIndex = purArr.findIndex(
        (sku) => sku.goodsInfoId == goodsInfoId
      );
      if (skuIndex > -1) {
        // 1.1.若已经存在缓存中,则[替换]数量
        purArr[skuIndex].goodsNum = goodsNum;
        localStorage.setItem(WMkit.purchaseCache(), JSON.stringify(purArr));
        return true;
      }
    }
    // 1.2.若不在,则加入缓存中
    addInPurchase(purArr, goodsInfoId, goodsNum);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * 加到购物车缓存中,并验证购物车数量限制
 * @author bail
 * @param purArr 购物车缓存
 * @param goodsInfoId skuId
 * @param goodsNum 商品数量
 */
const addInPurchase = (purArr, goodsInfoId, goodsNum) => {
  if (purArr.length >= Const.PURCHASE_MAX_SIZE) {
    // 验证是否超过购物车限制数量
    Alert({ text: '购物车容量达到50种上限！' });
  } else {
    purArr.unshift({ goodsInfoId, goodsNum }); // 插入到第一个(时间倒序排列)
    localStorage.setItem(WMkit.purchaseCache(), JSON.stringify(purArr));
  }
};

/**
 * 从购物车缓存中删除信息, 同时删除该商品选择的营销活动信息
 * @author bail
 * @param goodsInfoIds skuIdList
 */
const delPurchase = (goodsInfoIds) => {
  try {
    if (goodsInfoIds && goodsInfoIds.length > 0) {
      let purArr = JSON.parse(
        localStorage.getItem(WMkit.purchaseCache())
      ) as Array<any>;
      if (purArr) {
        // 过滤出 待删除ids中不存在的购物车信息
        purArr = purArr.filter(
          (sku) => goodsInfoIds.findIndex((id) => sku.goodsInfoId == id) == -1
        );
        localStorage.setItem(WMkit.purchaseCache(), JSON.stringify(purArr));
      }

      let marArr = JSON.parse(
        localStorage.getItem(cache.SKU_MARKETING_CACHE)
      ) as Array<any>;
      if (marArr) {
        // 过滤出 待删除ids中不存在的商品营销信息
        marArr = marArr.filter(
          (sku) => goodsInfoIds.findIndex((id) => sku.goodsInfoId == id) == -1
        );
        localStorage.setItem(cache.SKU_MARKETING_CACHE, JSON.stringify(marArr));
      }
    }
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * 未登录时,设置sku 参加的营销活动
 * @param goodsInfoId skuId
 * @param marketingId 营销活动id(满减/满折/满赠)
 */
const putSkuMarketingCache = (goodsInfoId, marketingId) => {
  try {
    // 未登录时,在前端存储,用户针对sku选择的营销活动信息
    let skuMarketingArr = new Array();
    const markCache = localStorage.getItem(cache.SKU_MARKETING_CACHE);
    if (markCache) {
      skuMarketingArr = JSON.parse(markCache);
    }
    const marIndex = skuMarketingArr.findIndex(
      (mar) => mar.goodsInfoId == goodsInfoId
    );
    if (marIndex > -1) {
      skuMarketingArr[marIndex] = { goodsInfoId, marketingId };
    } else {
      skuMarketingArr.push({ goodsInfoId, marketingId });
    }
    localStorage.setItem(
      cache.SKU_MARKETING_CACHE,
      JSON.stringify(skuMarketingArr)
    );
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * 登陆成功后,合并登录前与登陆后的购物车
 */
const mergePurchase = async (callBack) => {
  // 合并登录前/后的购物车
  const list = JSON.parse(localStorage.getItem(WMkit.purchaseCache()));
  if (list && list.length > 0) {
    const res = await Fetch('/site/mergePurchase', {
      method: 'POST',
      body: JSON.stringify({ purchaseMergeDTOList: list })
    });
    if (res.code == config.SUCCESS_CODE) {
      // 清空登录前购物车信息
      localStorage.removeItem(WMkit.purchaseCache());
      localStorage.removeItem(cache.SKU_MARKETING_CACHE);
    } else {
      Alert({ text: res.message });
    }
  }

  // 更新底部购物车数量
  msg.emit('purchaseNum');

  // 登陆弹框-登陆成功后的回调
  if (callBack) {
    if (typeof callBack == 'string') {
      eval('history.push("/")');
    } else {
      callBack();
    }
  }
};

export {
  postPurchase,
  putPurchase,
  delPurchase,
  putSkuMarketingCache,
  mergePurchase
};
