import React, { Component } from 'react';
import { history, WMkit } from 'wmkit';
import { msg } from 'plume2';
const dates = [
  {
    icon: 'icon-pingou',
    text: '拼购',
    press: '/groupon-center'
  },
  {
    icon: 'icon-repinpaihang',
    text: '热拼排行',
    press: '/groupon-selection'
  },
  {
    icon: 'icon-yingyongjieshao',
    text: '玩法介绍',
    press: '/groupon-rule'
  },
  {
    icon: 'icon-wodepingou',
    text: '我的拼购',
    press: '/group-order-list'
  }
];
export default class GrouponBottom extends Component<any, any> {
  props: {
    currTab?: string;
  };

  render() {
    const { currTab } = this.props;
    return (
      <div className="groupon-bottom">
        <ul style={{ marginBottom: 0 }}>
          {dates.map((value, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  if (value.text == '我的拼购' && !WMkit.isLogin()) {
                    msg.emit('loginModal:toggleVisible', {
                      callBack: () => {
                        // history.push(value.press);
                        history.replace(value.press);
                      }
                    });
                  } else {
                    // history.push(value.press);
                    history.replace(value.press);
                  }
                }}
                className={currTab == value.text ? 'curr' : ''}
              >
                <i className={`iconfont ${value.icon}`} />
                <span>{value.text}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
