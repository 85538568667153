/**
 * Image 组件
 *      1. 判断是否支持webp
 *      2. 兼容
 *
 *      <QMImage src={} width={} height={}/>
 */
import React, { PureComponent } from 'react'
import wrapUrl from './wrap-url'
import {renderZoomImage} from './zoom-image'

const defaultImg = require('../../images/none.png')

type tNoop = () => void

interface IImage {
  src: string,
  //https://help.aliyun.com/document_detail/44688.html?spm=5176.doc44704.6.926.UIEjnA
  /**
  *  lfit：等比缩放，限制在设定在指定w与h的矩形内的最大图片。
     mfit：等比缩放，延伸出指定w与h的矩形框外的最小图片。
     fill：固定宽高，将延伸出指定w与h的矩形框外的最小图片进行居中裁剪。
     pad：固定宽高，缩略填充
     fixed：固定宽高，强制缩略
  */
  mode?: string, // 裁剪模式
  formatToJPG?: boolean,
  devicePixelRatio?: number, //像素比
  errorSrc?: string,
  alt?: string,
  style?: object,
  className?: string,
  onError?: tNoop,
  onLoad?: Function,
  ifZoom?: boolean // 是否需要缩放
}

interface IWidth {
  width: string,
}

interface IHeight {
  height: string,
}

export type TImage = IImage & (IWidth | IHeight);

export default class Image extends PureComponent<TImage, any> {
  _image: any;

  state: {
    src: string
  }

  static defaultProps = {
    errorSrc: defaultImg
  }

  constructor(props) {
    super(props)
    this.state = {
      src: props.src || props.errorSrc
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      src: nextProps.src || nextProps.errorSrc
    })
  }

  render() {
    const { alt, style, className,width,height } = this.props as any;
    let params = Object.assign({}, this.props, { src: this.state.src })
    return (<img
      ref={img => this._image = img}
      src={wrapUrl(params)}
      alt={alt}
      onError={this.onError}
      style={style ? style : {width: width, height: height}}
      onLoad={this._onLoad}
      onClick={(e) => this.zoomImage(e, this.state.src, this.props.ifZoom)}
    />)
  }

  onError = (): void => {
    const { src } = this.state
    const { onError, errorSrc } = this.props;
    if (onError) {
      onError();
      return;
    }
    // 避免出现错误图片也加载不到, 导致重复加载
    if (errorSrc == src) {
      return;
    }
    if (errorSrc) {
      this.setState({ src: errorSrc })
    }
  }

  /**
  * 加入渐显效果
  */
  _onLoad = (e) => {
    if (this._image) {
      this._image.style.opacity = 1;
    }
    let { onLoad } = this.props;
    onLoad && onLoad(e);
  }

  zoomImage = (e, src, ifZoom) => {
    if (ifZoom){
      renderZoomImage({src})
    }
  }
}