import { Fetch } from 'wmkit';

/**
 * 查询购物车数量
 */
export const fetchPurchaseCount = () => Fetch('/site/countGoods');

/**
 * 查询平台端-社交分销总开关状态
 * 是否开启社交分销 0：关闭，1：开启
 */
export const qeuryOpenFlag = () => {
  return Fetch('/distribute/queryOpenFlag');
};

/**
 * 获取该会员作为分销员信息
 */
export const fetchDistributorInfo = () => {
  return Fetch(
    '/distribute/distributor-info',
    {},
    {
      showTip: false
    }
  );
};
