import React, {Component} from "react";

export interface TabItemStyles {
    tabs: any,
    tabItem: any,
    activity: any,
    curClass: string,
}

export interface TabItemProps {
    label: string
    isActive: boolean
    tabKey: string
    onTabClick: Function
    curClass?: string
}

export default class TabItem extends Component<TabItemProps, any> {

    props : {
        label: string
        isActive: boolean
        tabKey: string
        onTabClick: Function
        curClass?: string
    }

    render() {

        let { label, isActive, onTabClick, curClass} = this.props

        return (
            <div className={`layout-item ${ isActive ? (curClass? curClass : 'cur') : null }`} onClick={ () => onTabClick() }>{label}</div>
        )
    }
}
