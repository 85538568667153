import { Fetch } from 'wmkit';

type TResult = { code: string; message: string; context: any };

/**
 * 单个sku加入购物车
 */
export const purchaseSingle = (buyGoodsInfo) => {
  return Fetch<TResult>('/site/purchase', {
    method: 'POST',
    body: JSON.stringify({
      goodsInfoId: buyGoodsInfo.get('goodsInfoId'),
      goodsNum: buyGoodsInfo.get('num')
    })
  });
};

/**
 * 立即购买
 */
export const immediateBuy = (buyGoodsInfo) => {
  return Fetch<TResult>('/trade/immediate-buy', {
    method: 'POST',
    body: JSON.stringify({
      goodsInfoId: buyGoodsInfo.get('goodsInfoId'),
      buyCount: buyGoodsInfo.get('num')
    })
  });
};

/**
 * 立刻抢购
 * @param id
 */
export const rushToBuyFlashSaleGoods = (flashSaleGoodsId,num) => {
  return Fetch<Result<any>>('/flashsale/rushToBuyFlashSaleGoods', {
    method: 'POST',
    body: JSON.stringify({
      flashSaleGoodsId: flashSaleGoodsId,
      flashSaleGoodsNum:num
    })
  });
};
