import React from 'react';

import { WMImage } from 'wmkit';
import './css/style.css';

export default class GraphicShare extends React.PureComponent<any, any> {
  // props: {
  //   visible: boolean;
  //   changeVisible: Function;
  // };

  constructor(props) {
    super(props);
  }
  render() {
    // const { visible, changeVisible } = this.props;

    return (
      <div className="graphic-share">
        <div>
          <a href="javascript:;" className="close">
            <i className="iconfont icon-Close" />
          </a>

          {/*保存图片的区域 id=imgContent */}
          <div className="content" id="imgContent">
            <img
              className="w-logo"
              src="https://wanmi-b2b.oss-cn-shanghai.aliyuncs.com/201811141637241379"
              alt=""
            />
            <WMImage
              src={
                'https://wanmi-b2b.oss-cn-shanghai.aliyuncs.com/201802091123008206'
              }
              width="100%"
              height="100%"
            />
            <div className="infoBox">
              <div className="left">
                <p className="name">
                  外套 纱布 长款外套 纱布 长
                </p>
                <p className="spec">
                  粉色粉色粉色粉色粉色粉色粉色粉色粉色粉色粉色粉色粉色粉色粉色粉色粉色粉色粉色粉色
                </p>
                <p className="price-box">
                  <span className="price">
                    <span className="symbol">¥</span>
                    29.90
                  </span>
                  <span className="delPrice">¥60.00</span>
                </p>
                <p className="spec">来自XXXXXX的店铺</p>
              </div>
              <div className="right">
                <div className="code">
                  <img
                    className="codeImg"
                    src="https://wanmi-b2b.oss-cn-shanghai.aliyuncs.com/201901311504120272.jpeg"
                    alt=""
                  />
                </div>
                <p className="codeText">长按立即购买</p>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom">
          <div className="icon">
            <img src={require('./img/wechat.png')} alt="" />
            <p>微信</p>
          </div>
          <div className="icon">
            <img src={require('./img/moments.png')} alt="" />
            <p>朋友圈</p>
          </div>
          <div className="icon" onClick={() => {}}>
            <img src={require('./img/save.png')} alt="" />
            <p>保存图片</p>
          </div>
        </div>
      </div>
    );
  }
}
