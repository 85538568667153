import React from 'react'
import { msg } from 'plume2'
import { Alert, NumberInput, WMkit } from 'wmkit'
import { NumberInputProps } from 'wmkit/num-input';
import { config } from 'config';
import { putPurchase, delPurchase } from 'biz';
import * as webapi from './webapi'

interface GoodsNumProps extends NumberInputProps {
  goodsInfoId: string;
}

/**
 * 首页／商品列表／收藏列表 共用的步进器业务处理
 */
export default class GoodsNum extends React.Component<GoodsNumProps, any> {
	constructor(props) {
		super(props);
		this.state = {
			value: props.value
		};
	}

	render() {
		const value = this.state.value;

		return (
			<NumberInput
				{...this.props}
				value={value}
				onDelayChange={(num) =>
					this.handleNumChange(this.props.goodsInfoId, num)
				}
				onAddClick={(addDisabled, nextValue) => {
					if (addDisabled && nextValue > this.props.max) {
						Alert({text: `库存数量：${this.props.max}！`});
					}
				}}
			/>
		);
	}

	/**
	 * 处理商品采购数量变化
	 * @param goodsInfoId
	 * @param num
	 */
	handleNumChange = (goodsInfoId, num) => {
		if (num === 0) {
			if (WMkit.isLoginOrNotOpen()) {
				webapi.purchaseDelete(goodsInfoId).then(res => {
					if (res.code === config.SUCCESS_CODE) {
						// 重新设置state的value
						this.setState({value: num});
						this._handlePurchaseCount(num)
					} else {
						Alert({text: '修改失败！请重试'})
					}
				})
			} else {
				if (delPurchase([goodsInfoId])) {
					// 重新设置state的value
					this.setState({value: num});
					this._handlePurchaseCount(num);
				} else {
					Alert({text: '修改失败！请重试'})
				}
			}
		} else {
			if (num > this.props.max) {
				Alert({text: `库存数量：${this.props.max}！`});
				// 输入的值超过最大值，更新num为最大值
				num = this.props.max
			}

			if (WMkit.isLoginOrNotOpen()) {
				webapi.purchaseNumChange(goodsInfoId, num).then(res => {
					if (res.code == config.SUCCESS_CODE) {
						// 重新设置state的value
						this.setState({value: num});
						this._handlePurchaseCount(num)
					} else if (res.code === 'K-050121') {
						Alert({text: '购物车容量达到50种上限！'})
						// 添加失败，数量变回0
						this.setState({value: 0})
					} else {
						Alert({text: '修改失败！请重试'})
					}
				})
			} else {
				if (putPurchase(goodsInfoId, num)) {
					// 重新设置state的value
					this.setState({value: num});
					this._handlePurchaseCount(num);
				} else {
					Alert({text: '修改失败！请重试'})
				}
			}
		}

	}


	/**
	 * 获取并更新购物车总数量
	 * @private
	 */
	_handlePurchaseCount = (num) => {
		msg.emit('purchaseNum');
		this.props.onAfterClick && this.props.onAfterClick(num);
	}

}