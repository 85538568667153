import React, { Component } from 'react';
import { history, WMkit } from 'wmkit';

export default class PointsBlank extends Component<any, any> {
  props: {
    tips?: string;
    content?: string;
    img?: string;
    isToGoodsList?: boolean;
    style?: any;
  };

  static defaultProps = {
    content: '',
    img: 'http://pic.qianmi.com/astore/d2c-wx/images/list-none.png',
    isToGoodsList: false,
    styles: {},
  };

  render() {
    const { tips, content, img, isToGoodsList, style } = this.props;
    return (
      <div className="list-none" style={style}>
        <img src={img} />
        <p>{content}</p>
        <p className="grey-tips">{tips}</p>
        {isToGoodsList && (
          <div className="half">
            <button className="btn btn-ghost" onClick={() => this.go()}>
              逛逛商品
            </button>
          </div>
        )}
      </div>
    );
  }
  /**
   * 根据分销渠道跳转
   */
  go = () => {
    history.push('/points-mall');
  };
}
