import { Alert } from 'wmkit/modal';

interface ICheckParams {
  required?: boolean; //是否必填
  maxLength?: number; //最长长度
  minLength?: number; //最短长度
  regexString?: any; //直接传入正则表达式
  regexType?: string; //RegexMap中定义的公用正则
}

/**
 * 表单判断组件
 *
 * 调用方式：
 *     let checkType = {required : true , maxLength : 100 };
 *     let flag = FormRegexUtil( params , "开户行" , checkType );
 *
 * @param value
 * @param keyString
 * @param checkParams
 * @returns {boolean}
 * @constructor
 */

export default function FormRegexUtil(
  value,
  keyString,
  checkParams: ICheckParams = {
    required: false,
    maxLength: null,
    minLength: null,
    regexString: null,
    regexType: null
  }
) {
  //公用的正则可以在这配置，通过{regexType}参数传入
  let RegexMap = {
    accountNo: '^([1-9]{1})(\\d{15}|\\d{18})$',
    mobile: /^1(3|4|5|7|8|9)\d{9}$/,
    number: '^[0-9]*$',
    letter: '^[a-zA-Z]*$',
    'number&-': '^[0-9-]*$',
    'number&letter': '^[0-9a-zA-Z]*$'
  };

  let alertString;
  let flag = true;
  if (checkParams.required) {
    if (typeof value === 'string') {
      if (!value || '' == value.trim()) {
        alertString = '请填写' + keyString;
        flag = false;
      }
    } else if (value == undefined || value == null || value.toString().trim() == '') {
      alertString = '请填写' + keyString;
      flag = false;
    }
  }
  if (flag && value && (checkParams.maxLength || checkParams.minLength)) {
    if (checkParams.maxLength && checkParams.minLength) {
      if (
        value.length < checkParams.minLength ||
        value.length > checkParams.maxLength
      ) {
        alertString =
          keyString +
          ' 应该在' +
          checkParams.minLength +
          '～' +
          checkParams.maxLength +
          '个字符之间';
        flag = false;
      }
    } else if (checkParams.maxLength && value.length > checkParams.maxLength) {
      alertString = keyString + ' 不能大于' + checkParams.maxLength + '个字符';
      flag = false;
    } else if (checkParams.minLength && value.length < checkParams.minLength) {
      alertString = keyString + ' 不能小于' + checkParams.minLength + '个字符';
      flag = false;
    }
  }

  if (flag) {
    //{regexString},{regexType}同为正则判断
    //{regexString}优先级高于{regexType}
    //假如传如的{regexType}在RegexMap中不存在，则提示error
    if (checkParams.regexString && '' != checkParams.regexString) {
      flag = new RegExp(checkParams.regexString).test(value);
      if (!flag) {
        alertString = keyString + ' 输入错误';
      }
    } else if (checkParams.regexType && '' != checkParams.regexType) {
      let _regexType = checkParams.regexType;
      if (RegexMap.hasOwnProperty(_regexType)) {
        flag = new RegExp(RegexMap[_regexType]).test(value);
        if (!flag) {
          alertString = keyString + ' 输入错误';
        }
      } else {
        flag = false;
        console.log(' config[regexType not found] err...'); //正则Type未配置
      }
    }
  }
  if (alertString && alertString != '') {
    Alert({ text: alertString });
  }
  return flag;
}

/**
 * 同时验证去前后空格之后的输入值
 * 验证不为空
 * 验证最小长度
 * 验证最大长度
 * @param rule
 * @param value 输入值
 * @param callback 回调
 * @param fieldText 提示文本
 * @param minNum 最小值
 * @param maxNum 最大值
 */
export function validatorTrimMinAndMax(
  _rule,
  value,
  callback,
  fieldText,
  minNum,
  maxNum
) {
  if (!value) {
    callback(fieldText + '不能为空');
    return;
  } else {
    const val = value.toString();
    if (val.trim().length <= 0) {
      callback(fieldText + '不能为空');
      return;
    }
    // if (val.length != val.trim().length) {
    //   callback(fieldText + '前后不能有空格')
    //   return
    // }
    if (val.trim().length > 0 && val.trim().length < minNum) {
      callback(fieldText + '长度必须为' + minNum + '-' + maxNum + '个字符之间');
      return;
    }
    if (val.trim().length > minNum && val.trim().length > maxNum) {
      callback(fieldText + '长度必须为' + minNum + '-' + maxNum + '个字符之间');
      return;
    }
  }
  callback();
}
