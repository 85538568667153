const cacheName = (name) => {
  return name;
};

/**
 * storage的key，定义为常量统一存放
 */
export default {
  PURCHASE_CACHE: 'purchase-cache', // 用户未登录时的购物车本地缓存
  SKU_MARKETING_CACHE: 'sku-marketing-cache', // 用户未登录时,针对sku选择的营销活动缓存
  GOODS_FIRST_IMAGE: 'goods-first-image', //商品详情第一张图片
  IM_HISTORY: cacheName('HISTORY'), // 查询条件历史记录
  LOGIN_DATA: 'b2b-wechat@login', //h5端登录信息缓存
  FORGET_CODE: 'forgetpass-verticode', //修改密码时获取的验证码
  CUSTOMER_ID: 'forgetpass-customerId', //修改密码时发送验证码后返回过来的ID
  OLD_VERIFY_CODE: 'change-mobile-oldCode', //修改绑定手机号验证原来的验证码
  PAY_CODE: 'pay_code', //设置或忘记支付密码时发送的验证码
  ORDER_CONFIRM: cacheName('b2b@order-confirm'), //确认订单即时信息
  ORDER_CONFIRM_COUPON: cacheName('b2b-app@order-confirm-coupon'), //确认订单优惠券即时信息
  ORDER_CONFIRM_PAYTYPE: cacheName('b2b-app@order-confirm-paytype'), //确认订单支付方式临时信息
  ORDER_INVOICE: cacheName('b2b@order-invoice'), //确认订单发票临时信息
  ORDER_POINT: cacheName('b2b@order-point'), //确认订单积分临时信息
  LOGISTICS_INFO: 'logistics-info', // 退单列表填写的物流信息
  SELLER_ACCOUNT: cacheName('seller-account'), //填写付款单-收款账号
  AUTH_INFO: cacheName('wechat-auth-info'), //微信-授权信息
  PENDING_AND_REFUSED: cacheName('pending-or-refused-useInfo'), //审核中或者审核未通过的用户信息

  PAYMENT_REMARK: 'payment_remark', //付款单的备注
  PAYMENT_PRICE: 'payment_price', //付款单的备注
  PAYMENT_TIME: 'payment_time', //付款单的时间
  PAYMENT_ENCLOSES: 'payment_encloses', //付款单的附件
  SITE_PC_TITLE: 'site_pc_title',
  SITE_PC_ICO: 'SITE_PC_ICO',
  TARGET_PAGES: 'TARGET_PAGES', //拦截登录前需要访问的目标页面
  INVITEE_ID: 'invitee-id', // 邀请人id(邀请人的customerId)
  IS_DISTRIBUTOR: 'is-distributor', // 当前登录用户是否是可用分销员
  CHANNEL_TYPE: 'channel-type', // 分销渠道 1:商城 2:小店
  MY_PERFORMANCE: 'my_performance', //我的销售业绩显示/隐藏
  REGISTER_INFO: 'register_info', //注册信息，临时缓存
  MAIN_SCROLL_TOP: 'main_scroll_top', //首页滚动条位置
  QUALIFICATION_REMIND: 'QUALIFICATION_REMIND', // 资质认证是否已提醒 0：未提醒，1：已提醒
  IS_MASTER: 'IS_MASTER', // 是否主账号 0：是 1：否
  LIST_SELECT_GOODS: 'list-select-goods', // 推荐清单选择商品
  LIST_SELECT_ARTICLES: 'list-select-articles', //推荐清单选择文章
  LIST_TITLE: 'list_title', //推荐清单标题
  TP: 'time-on-page', //页面停留时长
  CUSTOMER_CURRENT_POSITION: 'customer_current_position', //会员当前的位置信息
  VALUE_ADDED_SERVICES: 'value-added-service', // 增值服务
  CMS_GROUP: 'cms_group', // CMS友群滚动条位置
  CMS_COURSE: 'cms_course', // CMS友课滚动条位置
  USER_AUTH_STATE: 'user-auth-state',//用户认证状态
  USER_AUTH_STATE_TIME: 'user-auth-state-time',//用户认证状态

  //h5-boss
  SITE_LOGO: 's2b-platform@logo', //商城logo
  MINI_QRCODE: 's2bboss-miniprogram-qrcode', //小程序二维码
  LOGIN_MENUS: 's2b-platform@menus', //登录人菜单信息
  LOGIN_FUNCTIONS: 's2b-platform@functions', //登录人功能权限信息
  SYSTEM_BASE_CONFIG: 's2b-platform@systemBaseConfig:', //系统配置，如pc，h5系统配置
};
