import React, { ReactNode } from 'react';

const noop = () => {};

export interface IInputProps {
  //输入项名称
  label?: string;
  //预留文案
  placeholder?: string;
  //input onchange
  onChange?: Function;
  //其它节点
  other?: ReactNode;
  //是否必填
  required?: boolean;
  //默认值
  value?: string;
  //最大长度
  maxLength?: number;
  // 是否只读
  readOnly?: boolean;
  // 字体大小
  fontMini?: boolean;
  className?: string;
  itemStyle?: Object;
  textStyle?: Object;
}

export default class FormText extends React.Component<IInputProps, any> {
  static defaultProps = {
    onChange: noop
  };

  constructor(props: IInputProps) {
    super(props);
  }

  render() {
    const {
      label,
      placeholder,
      onChange,
      other,
      required,
      value,
      maxLength,
      readOnly,
      fontMini,
      className,
      itemStyle,
      textStyle
    } = this.props;
    return (
      <div
        className={`row form-item form-align ${className || ''}`}
        style={itemStyle ? itemStyle : {}}
      >
        <span className="form-text" style={textStyle ? textStyle : {}}>
          {required && <i style={{ color: '#bd2d27' }}>*</i>}
          {label}
        </span>
        <div className="expandingArea">
          <div className="flex-text-wrap">
            <pre>
              <span>{value}</span>
            </pre>
            <textarea
              disabled={readOnly}
              style={{
                fontSize: fontMini ? 12 : null
              }}
              placeholder={placeholder}
              maxLength={maxLength}
              value={value}
              onChange={(val) => onChange(val)}
              readOnly={readOnly}
            />
          </div>
        </div>
        {other}
      </div>
    );
  }
}
