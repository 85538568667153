import React,{Component} from "react";
import {List} from 'immutable'
import TabItem, {TabItemProps,TabItemStyles} from "./tab-item";

interface TabsProps {
    data: List<TabItemProps>
    activeKey: string
    onTabClick: Function
    style: Object
    curClass?: string
}

export default class Tabs extends Component<TabsProps, any> {

    props: {
        data: List<TabItemProps> //数据源
        activeKey: string //选中的tabItem key
        onTabClick: Function //设置选中的relaxprops:key
        style: Object
        curClass?: string //选中tabitem的样式
    }

    render() {

        const {data, activeKey, onTabClick, style, curClass} = this.props


        return(
            <div className="layout-top">
              <div className="layout-content" style={style}>
                {
                    data.size > 0 ? data.toJS().filter(v =>  v.display == undefined ? true : v.display).map((v) => {
                        return <TabItem label={v.label} tabKey={v.tabKey}
                                        isActive={activeKey == v.tabKey}
                                        onTabClick={()=> onTabClick(v.tabKey)} curClass={curClass} />
                    })
                        : null
                }
              </div>
            </div>
        )
    }
}
