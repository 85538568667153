import React from 'react';
import videojs from 'video.js';

import './css/style.css';

/**
 * 商品详情页video组件
 */
export default class WMVideo extends React.Component<any, any> {
  player: any;
  videoNode: any;
  props: {
    closeVideo: Function,
    visible?: boolean,
    paused?: boolean
  }

  static defaultProps = {
    closeVideo: () => { },
    visible: false,
    paused: false
  }

  constructor(props) {
    super(props)
    this.state = {
      paused: this.props.paused,
      //手动暂停
      isPaused: false
    }
  }

  componentWillReceiveProps(nextProps) {        
    if ((nextProps.paused && !nextProps.visible) || this.state.isPaused) {
      this.player.pause()
    } else {
      this.player.play()
    }
  }

  componentDidMount() {
    // 实例化 Video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div className="wm-video-js no-select" style={{ display: this.props.visible ? 'block' : 'none' }}>
        <div className="videoBox">
          <div data-vjs-player>
            <video
              autoPlay={true}
              onPlay={() => this.props.paused && !this.props.visible? {}:this.setState({ isPaused: false })}
              onPause={() =>this.props.paused && !this.props.visible? {}:this.setState({ isPaused: true })}
              ref={(node) => (this.videoNode = node)}
              className="video-js vjs-big-play-centered vjs-paused"
            />
          </div>
          <div id="closeButton"
          onClick={(e) => {
               this.props.closeVideo()
               e.stopPropagation()
          }}>
            <i className="iconfont icon-guanbi"></i>
        </div>
        </div>
      </div>
    );
  }
}
