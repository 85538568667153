import { cache, config } from 'config';
import { Alert, Fetch, history, ValidConst, WMkit } from 'wmkit';
import storage from './storage';
import { message } from 'antd';
const WECHAT_AUTH_URL = 'https://open.weixin.qq.com/connect/oauth2/authorize';

/**
 * 获取登陆人id
 */
export const getUserId = (): string => {
  const loginData: any = JSON.parse(localStorage.getItem(cache.LOGIN_DATA));
  return loginData ? loginData.customerId : null;
};

/**
 * 判断是否登陆 & 设置参数
 */
export const isLogin = (): boolean => {
  if (window.token) {
    return true;
  } else {
    //获取token
    const data = localStorage.getItem(cache.LOGIN_DATA);
    refreshLogin();
    // 如果没有缓存数据，则需登陆
    if (!data) {
      return false;
    } else {
      //解析数据
      const wxData = JSON.parse(data);
      //全局保存token
      window.token = wxData.token;
      return true;
    }
  }
};


/**
 * @Description: 获取客户认证信息
 * @author malaian
 * @date 2020-10-17 11:55
 */
export const isAuthState = (): number => {
  let timeStamp=new Date().getTime();
  let saveTime=sessionStorage.getItem(cache.USER_AUTH_STATE_TIME) ? parseInt(sessionStorage.getItem(cache.USER_AUTH_STATE_TIME)) : -1;
  let diffTime=timeStamp-saveTime;
  if(diffTime>15*1000){
    console.log('时间大于15s查询')
    relayTestingAuthState()
    sessionStorage.setItem(cache.USER_AUTH_STATE_TIME,JSON.stringify(timeStamp));
  }
  return localStorage.getItem(cache.USER_AUTH_STATE) ? parseInt(localStorage.getItem(cache.USER_AUTH_STATE)) : -1;
};
// 这里校验用户认证状态
const relayTestingAuthState=async ()=>{
  // 这里校验用户认证状态
  const state = (await WMkit.getUserAuthState()) as any;
  localStorage.setItem(cache.USER_AUTH_STATE,JSON.stringify(state));
  sessionStorage.setItem(cache.USER_AUTH_STATE,JSON.stringify(state));
  //将当前的时间戳存入缓存
}

/**
 * 已登录 或 没有开放访问
 *   反之 未登录 且 开放访问
 * @returns {boolean}
 */
export const isLoginOrNotOpen = (): boolean => {
  return isLogin() || window.needLogin;
};

/**
 * 退出登录
 */
export const logout = () => {
  clearLoginCache();
  history.push('/user-center');
};

/**
 * 清空登录缓存信息
 */
export const clearLoginCache = () => {
  window.token = '';
  localStorage.removeItem(cache.LOGIN_DATA);
  sessionStorage.removeItem(cache.LOGIN_DATA);
  localStorage.removeItem(cache.AUTH_INFO);
  sessionStorage.removeItem(cache.IS_DISTRIBUTOR);
  localStorage.removeItem(cache.IS_DISTRIBUTOR);
  sessionStorage.removeItem(cache.MAIN_SCROLL_TOP);
  sessionStorage.removeItem(cache.CMS_GROUP);
  sessionStorage.removeItem(cache.CMS_COURSE);
  sessionStorage.removeItem(cache.USER_AUTH_STATE);
  sessionStorage.removeItem(cache.QUALIFICATION_REMIND);
  // sessionStorage.removeItem(cache.INVITEE_ID);
  // sessionStorage.removeItem(cache.CHANNEL_TYPE);
};

/**
 * 以后不需要此方法，仅限于系统域名
 * 获取D编号adminId
 * A874402
 */

export const getAdminId = (): string => {
  let adminId =
    (window.XSite_Info && window.XSite_Info['userId']) ||
    window.location.pathname.replace(/\//g, '') ||
    'A1211752';
  const storedId = sessionStorage.getItem('ADMINID') || '';
  if (storedId && storedId != adminId) {
    // 切换商城，清空缓存
    sessionStorage.clear();

    window.token = '';
  }
  sessionStorage.setItem('ADMINID', adminId);
  return adminId;
};
// /**
//  * 判断是否在微信浏览器内
//  */
// export const isInweixin = (): boolean => {
//   const ua = window.navigator.userAgent.toLowerCase();
//   return !!ua.match(/MicroMessenger/i);
// };
/**
 * 微信授权
 */
export const wechatGetOpenId = ({ redirectURL }) => {
  const scope = 'snsapi_base';
  //仅表示有无授权
  const state = 'b2bOpenId';
  const appid = window.wechatConfig ? window.wechatConfig.appId : '';
  if (!appid) {
    return;
  }
  redirectURL = encodeURIComponent(redirectURL);
  //授权地址
  const authURL = `${WECHAT_AUTH_URL}?appid=${appid}&redirect_uri=${redirectURL}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`;
  window.location.href = authURL;
};

/**
 * 校验密码，6-16个字母或数字
 */
export const testPass = (pass: string) => {
  const regex = /^[A-Za-z0-9]{6,16}$/;
  if (pass) {
    if (!regex.test(pass)) {
      Alert({
        text: '密码仅限6-16位字母或数字！'
      });
      return false;
    } else {
      return true;
    }
  } else {
    Alert({
      text: '请填写密码！'
    });
    return false;
  }
};

/**
 * 登录账号公共校验方法，登录账号既可以是手机号，也可以是 yly+6个字母的账号
 * @param account
 * @returns {boolean}
 */
export const testAccount = (account: string) => {
  const regex = ValidConst.accountName;
  if (account) {
    if (!regex.test(account)) {
      Alert({
        text: '无效的登录账号！'
      });
      return false;
    } else {
      return true;
    }
  } else {
    Alert({
      text: '请填写登录账号！'
    });
    return false;
  }
  return true;
};

/**
 * 手机号公共校验方法
 * @param tel
 * @returns {boolean}
 */
export const testTel = (tel: string) => {
  const regex = ValidConst.phone;
  if (tel) {
    if (!regex.test(tel)) {
      Alert({
        text: '无效的手机号！'
      });
      return false;
    } else {
      return true;
    }
  } else {
    Alert({
      text: '请填写手机号！'
    });
    return false;
  }
  return true;
};

/**
 * 手机验证码公共校验方法
 * @param tel
 * @returns {boolean}
 */
export const testVerificationCode = (code: string) => {
  const regex = /^\d{6}$/;
  if (code) {
    if (!regex.test(code)) {
      Alert({
        text: '验证码必须为6位数字!'
      });
      return false;
    } else {
      return true;
    }
  } else {
    Alert({
      text: '请填写验证码！'
    });
    return false;
  }
};

export const testToken = async () => {
  if (isLogin()) {
    let res = await Fetch('/login', {
      method: 'GET'
    });
    if (res.code != config.SUCCESS_CODE) {
      Alert({
        text: res.message,
        time: 2000
      });
      WMkit.logout();
    } else {
      window.token = res.context;
    }
  }
};

export function Base64() {
  const _keyStr =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  const _keyStrUrl =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_=';
  this.encode = function (input) {
    return encodebase(input, _keyStr);
  };

  // public method for decoding
  this.decode = function (input) {
    return decodebase(input, _keyStr);
  };

  this.urlEncode = function (input) {
    //将/号替换为_  将+号替换为-  后端采用 new String(Base64.getUrlDecoder().decode(encrypted.getBytes())) 进行解码
    return encodebase(input, _keyStrUrl);
  };

  this.urlDecode = function (input) {
    //将_号替换为/ 将-号替换为+
    return decodebase(input, _keyStrUrl);
  };

  const encodebase = (input, _keyStr) => {
    let output = '';
    let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    let i = 0;
    input = _utf8_encode(input);
    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);
      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;
      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output =
        output +
        _keyStr.charAt(enc1) +
        _keyStr.charAt(enc2) +
        _keyStr.charAt(enc3) +
        _keyStr.charAt(enc4);
    }
    return output;
  };

  const decodebase = (input, _keyStr) => {
    let output = '';
    let chr1, chr2, chr3;
    let enc1, enc2, enc3, enc4;
    let i = 0;
    input = input.replace(/[^A-Za-z0-9\+\/\=]/g, '');
    while (i < input.length) {
      enc1 = _keyStr.indexOf(input.charAt(i++));
      enc2 = _keyStr.indexOf(input.charAt(i++));
      enc3 = _keyStr.indexOf(input.charAt(i++));
      enc4 = _keyStr.indexOf(input.charAt(i++));
      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;
      output = output + String.fromCharCode(chr1);
      if (enc3 != 64) {
        output = output + String.fromCharCode(chr2);
      }
      if (enc4 != 64) {
        output = output + String.fromCharCode(chr3);
      }
    }
    output = _utf8_decode(output);
    return output;
  };

  // private method for UTF-8 encoding
  const _utf8_encode = (string) => {
    string = string.replace(/\r\n/g, '\n');
    let utftext = '';
    for (let n = 0; n < string.length; n++) {
      let c = string.charCodeAt(n);
      if (c < 128) {
        utftext += String.fromCharCode(c);
      } else if (c > 127 && c < 2048) {
        utftext += String.fromCharCode((c >> 6) | 192);
        utftext += String.fromCharCode((c & 63) | 128);
      } else {
        utftext += String.fromCharCode((c >> 12) | 224);
        utftext += String.fromCharCode(((c >> 6) & 63) | 128);
        utftext += String.fromCharCode((c & 63) | 128);
      }
    }
    return utftext;
  };

  // private method for UTF-8 decoding
  const _utf8_decode = (utftext) => {
    let string = '';
    let i = 0;
    let c,
      c2,
      c3 = 0;
    while (i < utftext.length) {
      c = utftext.charCodeAt(i);
      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        string += String.fromCharCode(
          ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        );
        i += 3;
      }
    }
    return string;
  };
}

/**
 * 防抖函数 - 延迟执行版
 *   场景介绍: 1.搜索框联想,等用户输入完毕后,延迟n秒后,检索出匹配的关键词
 * @param func 真正执行的业务函数
 * @param wait 延迟时间
 * @returns {()=>undefined}
 */
export function delayFunc(func, wait) {
  let timeout,
    context = null,
    args;
  wait = wait || 300;

  const later = function () {
    func.apply(context, args);
    timeout = context = args = null;
  };

  return function () {
    args = arguments;
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(later, wait);
  };
}

/**
 * 防抖函数 - 立即执行版
 *   场景例如: 确认下单,需要立即执行,但抛弃执行连续点击的后几次动作事件
 * @param func 真正执行的业务函数
 * @param wait n毫秒时间内只触发第一次
 * @returns {()=>undefined}
 */
export function onceFunc(func, wait) {
  let timeout,
    context = null,
    args;
  wait = wait || 800;

  const later = function () {
    timeout = context = args = null;
  };

  return function () {
    if (timeout) {
      clearTimeout(timeout);
    } else {
      args = arguments;
      func.apply(context, args);
    }
    timeout = setTimeout(later, wait);
  };
}

/**
 * 获取微信静默授权信息
 */
export const wechatOpenId = () => {
  let authInfo = {
    openid: ''
  };
  const storeAuthInfo = storage('local').get(cache.AUTH_INFO);
  if (storeAuthInfo) {
    authInfo = JSON.parse(storeAuthInfo);
  }
  return authInfo;
};

//弹框授权登录的处理逻辑
export const switchModalLogin = (context) => {
  // 首先区分企业会员
  if (context.enterpriseCheckState === 2) {
    // 审核通过,设置登陆后token以及登陆信息缓存
    window.token = context.token;
    localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
    sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
    //停在当前页面
    Alert({ text: '登录成功' });
    return;
  } else if (
    context.enterpriseCheckState === 1 ||
    context.enterpriseCheckState === 3
  ) {
    // 待审核或审核不通过
    WMkit.clearLoginCache();
    //将审核中的账户信息存入本地缓存
    storage('local').set(cache.PENDING_AND_REFUSED, JSON.stringify(context));
    history.push(`/improve-iep-info/${context.customerId}`);
    return;
  }
  // 普通会员
  switch (context.checkState) {
    /**审核中*/
    case 0:
      WMkit.clearLoginCache();
      //将审核中的账户信息存入本地缓存
      storage('local').set(cache.PENDING_AND_REFUSED, JSON.stringify(context));
      history.push(`/improve-information/${context.customerId}`);
      break;
    /**审核通过，成功登录*/
    case 1:
      // a.设置登陆后token以及登陆信息缓存
      window.token = context.token;
      localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
      sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
      //停在当前页面
      Alert({ text: '登录成功' });
      break;
    /**审核未通过*/
    default:
      WMkit.clearLoginCache();
      //将审核中的账户信息存入本地缓存
      storage('local').set(cache.PENDING_AND_REFUSED, JSON.stringify(context));
      history.push(`/improve-information/${(context as any).customerId}`);
  }
};

/**
 * 获取购物车缓存key
 *
 */
export const purchaseCache = (): string => {
  let purchaseCache = cache.PURCHASE_CACHE;
  // 获取分销渠道
  const pathname = window.location.pathname;
  if (isShop()) {
    // if (pathname.startsWith('/shop/')) {
    purchaseCache = purchaseCache + '-' + inviteeId();
  }

  return purchaseCache;
};

/**
 * 获取邀请人id
 */
export const inviteeId = (): string => {
  return sessionStorage.getItem(cache.INVITEE_ID);
};

/**
 * 获取分销渠道
 */
export const channelType = (): string => {
  return sessionStorage.getItem(cache.CHANNEL_TYPE);
};

/**
 * 是否shop购买
 */
export const isShop = (): boolean => {
  return channelType() == '2';
};

/**
 * 是否主账号
 */
export const isMaster = (): boolean => {
  return sessionStorage.getItem(cache.IS_MASTER) == '0';
};

/**
 * 是否以小B身份登录(同时满足：当前登录用户是分销员、邀请人id为空)
 */
export const isDistributorLogin = (): boolean => {
  return isDistributor() && !sessionStorage.getItem(cache.INVITEE_ID);
};

/**
 * 缓存分享链接信息
 * @param inviteeId 邀请人-会员ID
 * @param channelType  分销渠道 1:商城 2:小店
 */
export const setShareInfoCache = (inviteeId, channelType) => {
  if (inviteeId) {
    sessionStorage.setItem(cache.INVITEE_ID, inviteeId);
  }
  if (channelType) {
    sessionStorage.setItem(cache.CHANNEL_TYPE, channelType);
  }
};

/**
 * 当前登陆人是否是小B
 */
export const isDistributor = (): boolean => {
  const flag = isLogin() && localStorage.getItem(cache.IS_DISTRIBUTOR) == '1';
  if (overtime && isLogin()) {
    overtime = false;
    setIsDistributor().then((_res) => {
      setTimeout(() => (overtime = true), 1000 * 3);
    });
  }
  return flag;
};
let overtime = true;

/**
 * 查询并缓存是否分销员字段
 */
export const setIsDistributor = () => {
  return Fetch('/distribute/check/loginIsDistributor').then((res) => {
    if (res.code == config.SUCCESS_CODE) {
      localStorage.setItem(cache.IS_DISTRIBUTOR, res.context ? '1' : '0');
      const loginData = JSON.parse(localStorage.getItem(cache.LOGIN_DATA));
      loginData.customerDetail.isDistributor = res.context ? 1 : 0;
      localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(loginData));
    }
  });
};

/**
 * 是否以小B身份登录(同时满足：登录,当前登录用户是分销员,通过邀请链接进行访问)
 */
export const isDistributorLoginForShare = (): boolean => {
  return isDistributor() && !!sessionStorage.getItem(cache.INVITEE_ID);
};

/**
 * 退出分享链接到商城首页,并更新邀请人和分销渠道缓存(分销渠道变为商城)
 */
export const toMainPageAndClearInviteCache = () => {
  sessionStorage.removeItem(cache.INVITEE_ID);
  sessionStorage.setItem(cache.CHANNEL_TYPE, '1');
  history.push('/');
};

/**
 * 是否显示分享赚、发圈素材、只看分享赚按钮
 * @returns {boolean}
 */
export const isShowDistributionButton = (): boolean => {
  return isDistributor();
};

//将search信息转变成对象方便解析
export const searchToObj = (str: string) => {
  if (!str) {
    return;
  }
  str = str.substr(1);
  let arr = str.split('&'),
    obj = {},
    newArr = [];
  arr.map(function (value, index, arr) {
    newArr = value.split('=');
    if (newArr[0] != undefined) {
      obj[newArr[0]] = newArr[1];
    }
  });
  return obj;
};

/**
 * 查询分销员状态
 */
export const getDistributorStatus = () => {
  return Fetch<any>('/distribute/getDistributorStatus');
};
/**
 * 获取服务时间
 */
export const queryServerTime = () => {
  return Fetch('/system/queryServerTime');
};

export const testInviteCode = (inviteCode: string) => {
  const regex = /^[A-Z0-9]{8}$/;
  if (inviteCode) {
    if (!regex.test(inviteCode)) {
      Alert({
        text: '请填写正确的邀请码！'
      });
      return false;
    } else {
      return true;
    }
  } else {
    Alert({
      text: '请填写邀请码！'
    });
    return false;
  }
};

/**
 * 获取用户的认证状态 -1:未填写认证 0:认证审核中 1:认证通过 2:认证失败 3：资质过期
 */
export const getUserAuthState = async () => {
  let res = await Fetch('/customer/checkAuthValidDate');
  if (res.code != 'K-000000') {
    message.error(res.message);
    return -1;
  } else {
    return res.context;
  }
};

/**
 * 判断当前终端是否是 ios
 */
export const isIOS = () => {
  let u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
};

//初始化埋点数据，页面停留时长
export const dataPoint = (dataStr) => {
  return null;
  // return Fetch(
  //   '/statisticrecord/saveAndFlush',
  //   {
  //     method: 'POST',
  //     body:JSON.stringify(dataStr)
  //   }).then((res)=>{
  //   if (res.code == 'K-000000'){
  //     sessionStorage.setItem(cache.TP, JSON.stringify(res.context.statisticRecordVO));
  //   }
  // });
};
export const dataPoint2 = (time) => {
  if (JSON.parse(sessionStorage.getItem(cache.TP))) {
    let information = JSON.parse(sessionStorage.getItem(cache.TP));
    information.recordTime = time;
    // Fetch(
    //   '/statisticrecord/saveAndFlush',
    //   {
    //     method: 'POST',
    //     body:JSON.stringify(information)
    //   });
  }
};

export function refreshLogin() {
  let data = sessionStorage.getItem(cache.LOGIN_DATA);
  let login = localStorage.getItem(cache.LOGIN_DATA);
  if (!data && login) {
    sessionStorage.setItem(cache.SITE_LOGO, localStorage.getItem(cache.SITE_LOGO).toString());
    sessionStorage.setItem(cache.LOGIN_DATA, data);
    sessionStorage.setItem(cache.LOGIN_MENUS, localStorage.getItem(cache.LOGIN_MENUS));
    sessionStorage.setItem(cache.LOGIN_FUNCTIONS, localStorage.getItem(cache.LOGIN_FUNCTIONS));
    sessionStorage.setItem(cache.SYSTEM_BASE_CONFIG, localStorage.getItem(cache.SYSTEM_BASE_CONFIG));
  }
}
