import { Fetch } from 'wmkit'

type TResult = { code: string; message: string; context: any }

/**
 * 批量加入购物车
 */
export const purchase = (buyGoodsInfos) => {
  return Fetch<TResult>('/site/batchAdd',{
    method: 'POST',
    body: JSON.stringify({goodsInfos:buyGoodsInfos.toJS()})
  })
};

