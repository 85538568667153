import * as React from 'react';
import { div } from 'wmkit/common/util';
import { history } from 'wmkit';

const noneImg = require('./img/none.png');

export default class ListItem extends React.Component<any, any> {
  render() {
    const { goodInfo, key } = this.props;

    return (
      <div
        key={key}
        onClick={() =>
          history.push('/spellgroup-detail/' + goodInfo.get('goodsInfoId'))
        }
      >
        <div className="select-list">
          <div className="img-box">
            <img
              src={
                goodInfo.get('goodsImg') ? goodInfo.get('goodsImg') : noneImg
              }
              alt=""
            />
          </div>

          <div className="select-list-info">
            <span className="name">{goodInfo.get('goodsName')}</span>
            <div className="person-num">
              <span className="num-left">
                {goodInfo.get('grouponNum') || 0}
              </span>
              <span className="num-right">人团</span>
            </div>
            <div className="price">
              <span>￥{goodInfo.get('grouponPrice').toFixed(2) || '0.00'}</span>
              <span className="del-price">
                ￥{goodInfo.get('marketPrice').toFixed(2) || '0.00'}
              </span>
            </div>
            <span className="join-num">
              {goodInfo.get('alreadyGrouponNum')}人已拼团
            </span>
            <div className="go-join">去开团</div>
          </div>
        </div>
      </div>
    );
  }
}
