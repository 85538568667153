/*
 * @Author: lisong[of2601]
 * @Date: 2017-05-04 10:44:26
 */
import React, { Component } from 'react'
import ReactDOM from 'react-dom';

/**
 * 加载中组件
 * 组件中传递loading属性过来，false就不显示加载，true显示加载
 * 此组建内部可包含其他组件
 * 所以在component中需要有loading，可在store中添加本目录loading-actor，store中使用this.dispatch('common:loading', true | false)来设置loading状态
 * 导入loading-actor可使用：import LoadingActor from 'qmkit/loading/loading-actor'
 * 其他使用方法类似： https://ant.design/components/spin-cn/
 */
export interface LoadingProps {
  className?: string;
  loading?: boolean; //是否加载
  tip?: string; //自定义描述文案
  containerClassName?: string;
  //可以自己定义加载内容
  loadingElement?: React.ReactElement<any>;
  //是否显示遮罩层
  showMask?: boolean
}

export default class Loading extends Component<LoadingProps, any> {

  static defaultProps = {
    loading: true,
    showMask: false,
  }

  render() {
    let {
      loading,
      className,
      tip,
      containerClassName,
      loadingElement,
      children,
      showMask
    } = this.props;

    if (!loadingElement) {
      loadingElement = (
        <div className="loading-box">
          <div className="loading">
            {tip && <div>{tip}</div>}
          </div>
        </div>
      )
    }
    return (
      <div>
        {showMask && <div className="mask"></div>}
        {loading && <div key="loading">{loadingElement}</div>}
        {children && (
          <div className={containerClassName} key="container">
            {children}
          </div>
        )}
      </div>
    )
  }
}

/**
 * 提供可直接使用的方法
 * @param props
 * @param container
 */
export const renderLoading = (props?: LoadingProps, container?: Element) => {
  if (!container) {
    container = document.createElement('div');
    const app = document.getElementById('app');
    (app.firstElementChild || app).appendChild(container);
  }

  let LoadingReact = ReactDOM.render(<Loading {...props} />, container);
  let close = () => {
    ReactDOM.unmountComponentAtNode(container);
    container.remove();
  }

  return {
    LoadingReact,
    close
  };
}
