import React, { Component } from 'react';
import { Picker } from 'antd-mobile';

export default class P extends Component<any, any> {

  render() {
    return (
      <Picker
        data={[
          { value: 1, label: '政府机关/事业单位' },
          { value: 2, label: '国营' },
          { value: 3, label: '私营' },
          { value: 4, label: '中外合资' },
          { value: 5, label: '外资' },
          { value: 6, label: '其他' }
        ]}
        cols={1}
        {...this.props as any}
      >
        <Wrapper content={this.props.children as any}>
        </Wrapper>
      </ Picker>
    );
  }
}


class Wrapper extends React.Component<any, any> {

  render() {
    // 需要调用onClick方法弹出地址组件
    const { onClick, content } = this.props as any;
    return (
      <div onClick={onClick || undefined}>
        {content}
      </div>
    );
  }
}
