/**
 * ListView
 */
import React from 'react';
import Loading from 'wmkit/loading';
import LoadingMore from './loading-more';
import Fetch from '../fetch';
import noop from '../noop';
import { fromJS, is } from 'immutable';
import { arrayMove } from 'react-sortable-hoc';
import { Alert, WMkit } from 'wmkit';
//每页最大显示的页数
//默认是20 * 10(pageSize)
const MAX_CHUNK_PAGE_NUM = 20;

export default class ListView extends React.Component<any, any> {
  //当前的pageNum
  _pageNum: number;
  //大分页的pageNum
  _chunkPageNum: number;
  //当前是不是正在获取更多的数据
  _isLoadingMore: boolean;
  //loadMore组件的引用对象
  _loadingMoreComponent: any;
  props: {
    url?: string;
    // 从返回对象中取数据的属性,避免在公共组件中写死这种代码context.esGoodsInfoPage.content
    dataPropsName?: string;
    style?: Object;
    params?: Object;
    pageNum?: number;
    pageSize?: number;
    dataSource?: Array<any>;
    scrollRenderAheadDistance?: number;
    isShowLoadingMore?: boolean;
    isPagination?: boolean;
    renderHeader?: any;
    renderRow?: any;
    renderFooter?: any;
    renderEmpty?: any;
    renderLoading?: any;
    onDataReached?: (data: Object) => void;
    className?: string;
    //组装item需要的其他参数，和content平级的返回值
    otherProps?: any;
    toRefresh?: Function;
    // 获取系统时间
    getServerTime?: boolean;
    scrollHandle?: Function;
    extraData?: any;
    isSetHeight?: boolean;
  };

  state: {
    isFirstLoading: boolean;
    dataSource: Array<any>;
    noMore: boolean;
    otherPropsObject: any;
    serverTime: any;
    chunkPage: boolean;
  };

  static defaultProps = {
    //请求的url
    url: '',
    //样式
    style: {},
    //是否要设置高度
    isSetHeight: {},
    //http参数
    params: {},
    //默认当前页
    pageNum: 0,
    //默认每页展示的数量
    pageSize: 10,
    //提前多少px，发出ajax请求
    scrollRenderAheadDistance: 100,
    //当前的数据
    dataSource: [],
    //是否展示showLoadingMore
    isShowLoadingMore: true,
    //是否分页
    isPagination: true,
    //显示头部
    renderHeader: null,
    //展示每列
    renderRow: null,
    //展示页脚
    renderFooter: null,
    //显示空
    renderEmpty: null,
    //显示loading效果
    renderLoading: null,
    //收到数据后的回调
    onDataReached: noop,
    //可接受类名扩展
    className: '',
    //组装item需要的其他参数，和content平级的返回值
    otherProps: [],
    getServerTime: false,
    scrollHandle: noop,
    //多余的参数，state等变量
    extraData: {}
  };

  constructor(props) {
    super(props);

    const { pageNum, dataSource } = props;
    this._pageNum = pageNum;
    this._isLoadingMore = false;
    this._chunkPageNum = 1;

    this.state = {
      //是不是正在初始化
      isFirstLoading: true,
      //当前的数据源
      dataSource: dataSource || [],
      //数据到底
      noMore: false,
      otherPropsObject: {},
      serverTime: 0,
      chunkPage: false
    };
  }

  componentDidMount() {
    this._init();
    if (this.props.toRefresh) {
      this.props.toRefresh(this._init);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!is(fromJS(nextProps.params), fromJS(this.props.params))) {
      this._init(nextProps);
    } else if (nextProps.dataSource && nextProps.dataSource.length > 0) {
      this.setState({
        dataSource: nextProps.dataSource,
        noMore: true
      });
    }
  }

  render() {
    const {
      isPagination,
      renderLoading,
      renderHeader,
      renderRow,
      renderFooter,
      renderEmpty,
      className,
      extraData,
      pageNum,
      isSetHeight
    } = this.props;

    //提高滚动流畅度
    // if (document.body.style.overflowY != 'hidden') {
    //   document.body.style.overflowY = 'hidden';
    // }

    const style = {
      overflowY: 'scroll',
      height: isSetHeight ? 'auto' : window.innerHeight,
      WebkitOverflowScrolling: 'touch',
      ...this.props.style
    } as React.CSSProperties;

    //Loading效果
    //如果有自定义的loading， 显示自定义，否则显示默认的loading效果
    if (this.state.isFirstLoading) {
      return (
        <div style={style} className={className}>
          {renderHeader && renderHeader()}
          {renderLoading ? renderLoading() : <Loading />}
          {renderFooter && renderFooter()}
        </div>
      );
    }

    //如果数据为空
    if (extraData.toRefresh) {
      this._init();
    }
    const { dataSource, otherPropsObject, chunkPage } = this.state;

    if (dataSource.length == 0 && !chunkPage) {
      return (
        <div style={style} className={className}>
          {renderHeader && renderHeader()}
          {renderEmpty && renderEmpty()}
          {renderFooter && renderFooter()}
        </div>
      );
    }

    //如果数据不为空
    return (
      <div
        id="listViewPrt"
        className={className}
        onScroll={this._handleScroll}
        onTouchMove={this._touchMove}
        style={style}
      >
        {renderHeader && renderHeader()}

        {dataSource.map((data, index) =>
          renderRow(
            data,
            index,
            otherPropsObject,
            this.state.serverTime,
            pageNum
          )
        )}

        {this._renderLoadingMore()}
        {isPagination && this.state.noMore ? this._renderNomore() : null}
        {renderFooter && renderFooter()}
      </div>
    );
  }

  /**
   * 初始化数据
   */
  _init = async (props?) => {
    //之前设置为true 禁止加载数据的锁解开
    this._isLoadingMore = false;
    this.setState({
      isFirstLoading: true,
      noMore: false
    });
    this._pageNum = 0;
    await this._getServerTime();
    props = props || this.props;
    const { url, pageSize, otherProps } = props;

    //如果url不为空，fetch去访问
    if (url != '') {
      const res = await Fetch(url, {
        method: 'POST',
        body: JSON.stringify(this._getParams(props))
      });

      //todo error
      // if (false) {
      //   //show error
      //   //全局的error提示
      //   this.setState({
      //     isFirstLoading: false
      //   })
      //   return;
      // }
      let dataList;
      const context = res.context as any;

      if (this.props.dataPropsName) {
        // dataPropsName若存在,则遍历属性名取嵌套的数据
        dataList = res as any;
        const propNmArr = this.props.dataPropsName.split('.');
        propNmArr.forEach((propNm) => {
          dataList = dataList[propNm];
        });
      } else {
        // dataPropsName若不存在,按照原有方式取值,兼容老接口
        dataList =
          (context &&
            ((context.esGoodsInfoPage && context.esGoodsInfoPage.content) ||
              context.content ||
              (context.goodsInfos && context.goodsInfos.content) ||
              (context.couponViews && context.couponViews.content) ||
              (context.couponCodeVos && context.couponCodeVos.content) ||
              (context.esGoodsInfoResponse &&
                context.esGoodsInfoResponse.esGoodsInfoPage.content) ||
              (context.grouponCenterVOList &&
                context.grouponCenterVOList.content) ||
              //兼容 '/goods/spus'接口的返回值
              (context.esGoods && context.esGoods.content) ||
              //兼容 '/smallshopcoupon/page' 我的优购优购码 返回值
              (context.smallShopCouponVOPage &&
                context.smallShopCouponVOPage.content) ||
              (context.learningRecordVOPage &&
                context.learningRecordVOPage.content) ||
              (context.articleInfoVOPage &&
                context.articleInfoVOPage.content) ||
              (context.esArticleInfoPage &&
                context.esArticleInfoPage.content) ||
              (context.customerArticleCommentVOPage &&
                context.customerArticleCommentVOPage.content))) ||
              (context && context.detailResponseList) ||
          [];
      }

      let otherPropsObject = {};
      if (otherProps && otherProps.length > 0 && context) {
        otherProps.forEach((item) => {
          let propTmp = context;
          const itemPropArr = item.split('.');
          itemPropArr.forEach((propNm) => {
            propTmp = propTmp[propNm];
          });
          otherPropsObject[item] = propTmp || {};
        });
      }
      dataList = dataList.map((data) => {
        data._otherProps = otherPropsObject;
        return data;
      });
      this.setState(
        {
          isFirstLoading: false,
          dataSource: dataList,
          noMore: dataList.length < this.props.pageSize,
          otherPropsObject: otherPropsObject
        },
        () => {
          //通知父组件数据
          if (props.onDataReached) {
            props.onDataReached(res);
          }
        }
      );
    } else {
      this.setState({
        isFirstLoading: false
      });
    }
  };

  /**
   * 处理页面滚动
   */
  _handleScroll = (e) => {
    this.props.scrollHandle(e.target.scrollTop);
    const { isPagination, scrollRenderAheadDistance } = this.props;

    let listViewPrt = document.getElementById('listViewPrt');

    // listViewPrt.style.height = window.innerHeight - listViewPrt.offsetTop+'px'
    //如果不分页，或者没有更多数据，直接返回
    if (!isPagination || this.state.noMore) {
      return;
    }

    const { clientHeight, scrollHeight, scrollTop } = e.target;

    //分页时机
    if (scrollHeight - clientHeight <= scrollTop + scrollRenderAheadDistance) {
      //像京东一样，每chunkPage展示10页数据
      //超过200条，手动分页
      // if (this.state.dataSource.length >= this._getChunkPageSize()) {
      //   this._chunkPageNum =
      //     ((this._pageNum + 1) * this.props.pageSize) /
      //     this._getChunkPageSize();
      //
      //   this._loadingMoreComponent.setting({
      //     isLoadingMore: true,
      //     isShowPageAction: true,
      //     chunkPageNum: this._chunkPageNum
      //   });
      //   return;
      // }

      this._handlePagination();
    }
  };

  /**
   * 兼容ios 滚动
   */
  _touchMove = (e) => {
    this.props.scrollHandle(e.target.scrollTop);
    const { isPagination, scrollRenderAheadDistance } = this.props;

    let listViewPrt = document.getElementById('listViewPrt');

    // listViewPrt.style.height = window.innerHeight - listViewPrt.offsetTop+'px'
    //如果不分页，或者没有更多数据，直接返回
    if (!isPagination || this.state.noMore) {
      return;
    }

    var scrollTop = window.scrollY;

    //变量clientHeight是可视区的高度

    var clientHeight =
      document.documentElement.clientHeight || document.body.clientHeight;

    //变量scrollHeight是滚动条的总高度

    var scrollHeight = listViewPrt.scrollHeight;

    //分页时机
    if (scrollTop + scrollRenderAheadDistance >= scrollHeight - clientHeight) {
      //像京东一样，每chunkPage展示10页数据
      //超过200条，手动分页
      // if (this.state.dataSource.length >= this._getChunkPageSize()) {
      //   this._chunkPageNum =
      //     ((this._pageNum + 1) * this.props.pageSize) /
      //     this._getChunkPageSize();
      //
      //   this._loadingMoreComponent.setting({
      //     isLoadingMore: true,
      //     isShowPageAction: true,
      //     chunkPageNum: this._chunkPageNum
      //   });
      //   return;
      // }

      this._handlePagination();
    }
  };

  _handlePagination = async () => {
    //防止重复获取数据
    if (this._isLoadingMore) {
      return;
    }
    await this._getServerTime();
    this._isLoadingMore = true;
    this._pageNum++;
    this._showLoadingMore();
    const { url, otherProps } = this.props;
    const res = await Fetch(url, {
      method: 'POST',
      body: JSON.stringify(this._getParams())
    });

    let dataList;
    const context = res.context as any;
    if (this.props.dataPropsName) {
      // dataPropsName若存在,则遍历属性名取嵌套的数据
      dataList = res as any;
      const propNmArr = this.props.dataPropsName.split('.');
      propNmArr.forEach((propNm) => {
        dataList = dataList[propNm];
      });
    } else {
      // dataPropsName若不存在,按照原有方式取值,兼容老接口
      dataList =
        (context &&
          ((context.esGoodsInfoPage && context.esGoodsInfoPage.content) ||
            context.content ||
            (context.goodsInfos && context.goodsInfos.content) ||
            (context.couponViews && context.couponViews.content) ||
            (context.couponCodeVos && context.couponCodeVos.content) ||
            (context.esGoodsInfoResponse &&
              context.esGoodsInfoResponse.esGoodsInfoPage.content) ||
            (context.grouponCenterVOList &&
              context.grouponCenterVOList.content))) ||
            (context && context.detailResponseList) ||
        [];
    }

    let otherPropsObject = {};
    if (otherProps && otherProps.length > 0 && context) {
      otherProps.forEach((item) => {
        let propTmp = context;
        const itemPropArr = item.split('.');
        itemPropArr.forEach((propNm) => {
          propTmp = propTmp[propNm];
        });
        otherPropsObject[item] = propTmp || {};
      });
    }

    if (!res || dataList.length == 0) {
      //show error
      this._pageNum--;
      this._hideLoadingMore();
      this.setState({
        noMore: true,
        chunkPage: false
      });
      return;
    }

    this._isLoadingMore = false;
    dataList = dataList.map((data) => {
      data._otherProps = otherPropsObject;
      return data;
    });
    this.setState(
      {
        dataSource: [...this.state.dataSource, ...dataList],
        noMore: dataList.length < this.props.pageSize,
        otherPropsObject: fromJS(this.state.otherPropsObject)
          .mergeDeep(fromJS(otherPropsObject))
          .toJS(),
        chunkPage: false
      },
      () => {
        this._hideLoadingMore();
        if (this.props.onDataReached) {
          this.props.onDataReached(res);
        }
      }
    );
  };

  /**
   * 获取参数
   */
  _getParams(props?) {
    const { pageSize, params } = props || this.props;

    return {
      ...params,
      pageNum: this._pageNum,
      pageSize
    };
  }

  /**
   * 渲染loading-more
   */
  _renderLoadingMore() {
    return (
      <LoadingMore
        ref={(loadingMore) => (this._loadingMoreComponent = loadingMore)}
        isShowLoadingMore={this.props.isShowLoadingMore}
        onPrev={this._handlePreChunkPage}
        onNext={this._handleNextChunkPage}
      />
    );
  }
  /**
   * 没有更多了提示
   */
  _renderNomore() {
    return <div className="no-more-tips">没有更多了</div>;
  }

  _hideLoadingMore() {
    this._loadingMoreComponent.setting({
      isLoadingMore: false
    });
  }

  _showLoadingMore() {
    this._loadingMoreComponent.setting({
      isLoadingMore: true
    });
  }

  _getChunkPageSize() {
    return MAX_CHUNK_PAGE_NUM * this.props.pageSize;
  }

  _handlePreChunkPage = () => {
    this._chunkPageNum--;
    this._pageNum = this._pageNum - 2 * MAX_CHUNK_PAGE_NUM;
    this._loadingMoreComponent.setting({
      isLoadingMore: true,
      isShowPageAction: true,
      chunkPageNum: this._chunkPageNum
    });
    this.setState({
      chunkPage: true,
      dataSource: []
    });
    this._handlePagination();
  };

  _handleNextChunkPage = () => {
    this._chunkPageNum++;
    this._loadingMoreComponent.setting({
      isLoadingMore: true,
      isShowPageAction: true,
      chunkPageNum: this._chunkPageNum
    });
    this.setState({
      chunkPage: true,
      dataSource: []
    });
    this._handlePagination();
  };

  _changeArray = (oldIndex, newIndex) => {
    let dataList = this.state.dataSource;
    dataList = arrayMove(dataList, oldIndex, newIndex);
    this.setState({ dataSource: dataList });
  };

  _updateDataSource = (index) => {
    let dataList = fromJS(this.state.dataSource);
    let newDataSource = dataList.delete(index);
    this.setState({ dataSource: newDataSource.toJS() }, () => {
      Alert({ text: '删除成功' });
    });
  };

  _changeDateSource = (param) => {
    let dataList = this.state.dataSource;
    dataList[param.index] = param.data;
    this.setState({ dataSource: dataList });
  };

  /**
   *  获取系统时间
   * */
  _getServerTime = async () => {
    if (this.props.getServerTime) {
      //获取服务时间
      const serverTime = await WMkit.queryServerTime();
      this.setState({ serverTime: serverTime });
    }
  };
}

/*const rowStyle = {
  height: 80,
  'border-top': '1px solid #ccc'
}

const row = (data, index) => (
  <div style={rowStyle}>{index}</div>
)

ReactDOM.render(
  <ListView
    style={{ height: 200 }}
    url='/__mock__/post.json'
    renderHeader={() => <div style={{ height: 100, backgroundColor: 'orange' }}>Header</div>}
    renderRow={row}
  />,
  document.getElementById('app')
)*/
