import React from 'react';
import { noop } from 'wmkit';

export default class WMShareModal extends React.PureComponent<any, any> {
  static defaultProps = {
    //弹层显示与否
    showModal: true,
    //点击弹层关闭方法
    onChangeShow: noop
  };

  render() {
    const { showModal, onChangeShow } = this.props;
    return showModal ? (
      <div
        className="wm-share-modal"
        onClick={() => {
          onChangeShow();
        }}
      >
        <div className="wm-share-modal-body">
          <img src={require('./img/share.png')} />
          <p>点击这里进行分享</p>
        </div>
      </div>
    ) : null;
  }
}
