import React from 'react';
import { Relax } from 'plume2';
import { noop } from 'wmkit';
@Relax
export default class Privacy extends React.Component<any, any> {
  props: {
    relaxProps?: {
      toggleShowPrivacyShow: Function;
      registerEnterpriseContent: string;
    };
  };

  static relaxProps = {
    toggleShowPrivacyShow: noop
  };

  render() {
    const { toggleShowPrivacyShow } = this.props.relaxProps;
    return (
      <div className="agree-content" onTouchMove={e=> e.stopPropagation()}>
        <a
          href="javascript:void(0);"
          className="closeBox"
          onClick={() => toggleShowPrivacyShow()}
        >
          <i className="iconfont icon-jiantou" />
        </a>
        <div className="agree-box noPadding">
          <iframe
          style={{border:0,width:'100%',height:'100%'}}
          src={'https://m.ylyuan.com/page/article/1599557378530'}
          />
        </div>
      </div>
    );
  }
}
