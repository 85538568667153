/**
 * Created by feitingting on 2017/7/11.
 */
import { Fetch } from 'wmkit';
/**
 * 登录系统
 * @param   account,passwordFetch
 * @returns
 */
export const login = (account: string, pass: string) => {
  return Fetch('/login', {
    method: 'POST',
    body: JSON.stringify({
      customerAccount: account,
      customerPassword: pass
    })
  });
};

/**
 * 获取登录页logo
 * @returns {Promise<Result<T>>}
 */
export const fetchBaseConfig = () => {
  return Fetch('/system/baseConfig', {
    method: 'GET'
  });
};

/**
 * 验证token
 * @returns {Promise<Result<T>>}
 */
export const isLogin = () => {
  return Fetch('/login', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization:
        'Bearer' + ((window as any).token ? ' ' + (window as any).token : '')
    }
  });
};

/**
 * 获取微信授权登录开关
 */
export const fetchWxLoginStatus = () => {
  return Fetch('/third/login/wechat/status/MOBILE');
};

/**
 * 验证码登录  发送验证码
 * @type {Promise<AsyncResult<T>>}
 */
export const sendCode = (mobile, showLogin) => {
  return showLogin
    ? Fetch(`/login/verification/${mobile}`, {
        method: 'POST'
      })
    : Fetch(`/checkSmsByRegister/web/modal/${mobile}`, {
        method: 'POST'
      });
};



export const sendEnterpriseRegisterCode = (tel,uuid,patchca) => {
  return Fetch('/checkSmsByRegister', {
    method: 'POST',
    body: JSON.stringify({
      customerAccount: tel,
      patchca: patchca,
      uuid: uuid
    })
  });
};



/**
 * 验证码登录系统
 * @param account
 * @param verificationCode
 * @returns {Promise<Result<T>>}
 */
export const loginWithVerificationCode = (
  account: string,
  verificationCode: string
) => {
  return Fetch('/login/verification', {
    method: 'POST',
    body: JSON.stringify({
      customerAccount: account,
      verificationCode: verificationCode
    })
  });
};

/**
 * 登录系统
 * @param mobile
 * @param code
 * @param password
 * @returns {Promise<Result<T>>}
 */
export const register = (
  mobile: string,
  password: string,
  code: string,
  patchca: string,
  uuid: string,
  employeeId: string,
  shareUserId: string,
  inviteCode: string
) => {
  return Fetch('/register', {
    method: 'POST',
    body: JSON.stringify({
      customerAccount: mobile,
      customerPassword: password,
      verifyCode: code,
      patchca: patchca,
      uuid: uuid,
      employeeId: employeeId,
      shareUserId: shareUserId,
      inviteCode: inviteCode
    })
  });
};

/**
 * 弹框注册，不携带图形验证码
 * @param account
 * @param telCode
 * @param password
 */
export const registerModal = (
  account,
  password,
  telCode,
  inviteeId,
  shareUserId,
  inviteCode
) => {
  return Fetch('/register/modal', {
    method: 'POST',
    body: JSON.stringify({
      customerAccount: account,
      customerPassword: password,
      verifyCode: telCode,
      inviteeId: inviteeId,
      shareUserId: shareUserId,
      inviteCode: inviteCode
    })
  });
};

/**
 * 完善账户信息
 * @param params
 */
export const doPerfect = (params = {}) => {
  return Fetch('/perfect', {
    method: 'POST',
    body: JSON.stringify({
      ...params
    })
  });
};

/**
 *
 * @param params
 */
export const doRegisterEnterprise = (params = {}) =>{
  return Fetch('/registerEnterprise', {
    method: 'POST',
    body: JSON.stringify({
      ...params
    })
  });
};

/**
 * 根据客户id查询客户信息
 * @param {string} customerId
 * @returns {Promise<Result<any>>}
 */
export const getCustomerInfo = (customerId: string) => {
  return Fetch(`/customer/customerInfoById/${customerId}`, {
    method: 'GET'
  });
};

/**
 * 注册验证
 * @param {string} mobile
 * @param {string} code
 * @returns {Promise<Result<any>>}
 */
export const checkRegister = (mobile: string, code: string,fromPage:number) => {
  return Fetch('/register/check', {
    method: 'POST',
    body: JSON.stringify({
      customerAccount: mobile,
      verifyCode: code,
        fromPage:fromPage
    })
  });
};

/**
 * 获取注册限制
 */
export const getRegisterLimitType = () =>
  Fetch('/getRegisterLimitType', { method: 'POST' });
