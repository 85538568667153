import React from 'react';
import { DatePicker } from 'antd-mobile';
import { FormSelect } from 'wmkit';

export default class AntdDatePicker extends React.Component<any, any> {
  static defaultProps = {
    value: '',
    onChange: () => {},
    formLabel: '',
    formPlaceholder: '',
    minDate: new Date('1900-01-01'),
    maxDate: new Date('2099-12-31')
  };
  render() {
    const {
      required,
      mode,
      minDate,
      maxDate,
      formLabel,
      formPlaceholder,
      value,
      onChange,
      disabledFlag
    } = this.props;
    return (
      <DatePicker
        mode={mode || 'date'}
        title="选择日期"
        extra="选择日期"
        minDate={minDate}
        maxDate={maxDate}
        value={value}
        onChange={(date) => onChange(date)}
        {...this.props}
      >
        <Wrapper
          content={formLabel}
          formPlaceholder={formPlaceholder}
          required={required}
          disabledFlag={disabledFlag}
        />
      </DatePicker>
    );
  }
}

class Wrapper extends React.Component<any, any> {
  render() {
    const { onClick, content, formPlaceholder, required,disabledFlag } = this.props;
    return (
      <div onClick={onClick || undefined}>
        <FormSelect
          labelName={content}
          placeholder={formPlaceholder ? formPlaceholder : '请选择' + content}
          required={required}
          disabledFlag={disabledFlag}
        />
      </div>
    );
  }
}


