import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { _, Alert, Fetch } from 'wmkit';
import { renderLoading } from 'wmkit/loading';

import canvasCompress from './canvas-compress';

import { uploadImg } from '../weixin/wx-upload-image';
import getUid from './uid';
import { config } from 'config';

const IMAGE_TYPE = {
  '.jpg': 'image/jpeg',
  '.png': 'image/png',
  '.jpeg': 'image/jpeg',
  '.gif': 'image/gif',
  '.heic': 'image/heic' // ios 11新增格式 live图
};

/**
 * 上传图片
 * 区分微信和浏览器
 * onSuccess 回传 上传后api的结果
 * container 可无
 */
export default class UploadImage extends Component<any, any> {
  props: {
    Tag?: string; //父元素标签 默认span
    style?: object;
    className?: string;
    disabled?: boolean; //true，隐藏父元素事件
    children?; //子元素
    action?: string; //浏览器请求上传地址
    compressLowerlimitMB?: number; //压缩下限大小(只针对浏览器上传，微信上传不限制)
    quality?: number; //压缩比例 1~100
    onSuccess: Function; //参数是promise，请注意
    onLoadChecked?: () => boolean; //上传验证，为false时不进行上传
    onProgress?: (Event) => void;
    onLoadStart?: (Event) => void;
    onLoadEnd?: (Event) => void;
    onAbort?: (Event) => void;
    onError?: (Event) => void;
    repeat?: boolean; //是否可以重复上传同一张图片
    fileType?: string; //图片类型
    size?: number; //图片大小M,默认10M
  };

  static defaultProps = {
    action: '/common/uploadResource?resourceType=IMAGE',
    Tag: 'span',
    disabled: false,
    repeat: false,
    size: 10
  };

  constructor(props) {
    super(props);
    this.state = { uid: getUid() };
  }

  componentWillUnmount() { }

  render() {
    const { Tag, className, disabled, style, children, fileType } = this.props;

    const events = disabled
      ? {}
      : {
        onClick: this._onClick,
        tabIndex: '0'
      };

    return (
      <Tag {...events} className={className} role="button" style={style}>
        <input
          type="file"
          ref="file"
          key={this.state.uid}
          accept={'image/*'}
          style={{ display: 'none' }}
          onChange={(e) => this._onChange(e)}
        />
        {children}
      </Tag>
    );
  }

  _onClick = (e) => {
    let { onLoadChecked } = this.props;
    //上传之前进行验证
    if (onLoadChecked && !onLoadChecked()) {
      return false;
    }

    // if (_.isWeixin() && window['wechatConfig']) {
    //   //微信中
    //   this._upladImageInWeixin();
    //   return false;
    // }

    const el = this.refs.file as any;
    if (!el) {
      return false;
    }
    el.click();
  };

  //浏览器上传
  _onChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      const MAX_SIZE = 1024 * 1024 * this.props.size;
      if (file.size > MAX_SIZE) {
        Alert({
          text: '图片尺寸过大，请重新上传，不能超过' + this.props.size + 'M'
        });
        this._clearValue();
        return false;
      }
      if (!this._filterImgType(file.type)) {
        this._clearValue();
        return false;
      }
      //从压缩开始
      const { close } = renderLoading({
        /*tip: '图片上传中...', */ showMask: true
      });
      this._closeLoading = close;

      let { compressLowerlimitMB, quality } = this.props;
      this._compressToUploadImage(file, compressLowerlimitMB, quality);
    }
  };

  /**
   * 微信中使用click,返回false
   */
  _upladImageInWeixin = async () => {
    this.props.onSuccess(await uploadImg());
    let mask = document.getElementById('wx-upload-image-mask');
    ReactDOM.unmountComponentAtNode(mask);
    mask.remove();
  };

  /**
   * 上传图片，如果图片大就压缩
   */
  _compressToUploadImage = (file, compressLowerlimitMB = 1, quality = 50) => {
    if ((window as any).FileReader && window.Blob) {
      //压缩限制
      const compressSizeFlag = file.size > compressLowerlimitMB * 1024 * 1024;

      const fileReader = new FileReader();
      const {
        onAbort,
        onError,
        onLoadStart,
        onLoadEnd,
        onProgress,
        onSuccess
      } = this.props;

      onAbort && (fileReader.onabort = onAbort);
      onError && (fileReader.onerror = onError);
      onLoadStart && (fileReader.onloadstart = onLoadStart);
      onLoadEnd && (fileReader.onloadend = onLoadEnd);
      onProgress && (fileReader.onprogress = onProgress);

      fileReader.onload = (e) => {
        //上传结束
        let src = e.target['result'];
        if (compressSizeFlag) {
          canvasCompress(src, quality).then((dataUrl) => {
            this._uploadImageToServer(dataUrl, file);
          });
        } else {
          //直接上传
          this._uploadImageToServer(src, file);
        }
      };

      fileReader.readAsDataURL(file);
    } else {
      Alert({ text: '浏览器不支持上传' });
      this._closeLoading && this._closeLoading();
    }
  };

  /**
   * 调用api上传
   */
  _uploadImageToServer = async (base64, file) => {
    console.log('********** 调用api开始上传 **********');
    let type = file.type;
    let data = {
      fileData: base64.split(';')[1].split(',')[1],
      imageType: type.split('/')[1]
    };

    let formData = new FormData();
    formData.append('uploadFile', file);
    const upRes = await Fetch(
      '/common/uploadResource?resourceType=IMAGE',
      {
        method: 'POST',
        body: formData
      },
      { isUpload: true }
    );
    if (upRes.code != config.SUCCESS_CODE) {
      Alert({ text: '上传失败，请稍后重试', time: 1500 });
      this._closeLoading && this._closeLoading();
      return;
    }

    this.props.onSuccess(
      upRes,
      file,
      base64
    );
    //允许重复上传同一张照片
    this.props.repeat && this._clearValue();
    this._closeLoading && this._closeLoading();
  };

  /**
   * 过滤图片类型
   * @private
   */
  _filterImgType(type) {
    if (this.props.fileType) {
      const types = this.props.fileType.split(',');
      if (!types.some((t) => type === IMAGE_TYPE[t])) {
        Alert({
          text: '图片格式不正确,只能上传' + this.props.fileType + '格式'
        });
        return false;
      }
    } else {
      let imageType = /^image\//;
      if (!imageType.test(type)) {
        Alert({ text: '图片格式不正确' });
        return false;
      }
    }
    return true;
  }

  /**
   * 清空文件选择框中的文本,支持上传同样的文件
   */
  _clearValue = () => {
    if (this.refs.file) {
      (this.refs.file as any).value = '';
    }
  };

  _closeLoading = () => { };
}
