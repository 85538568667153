import React, { Component } from 'react';
import { Relax } from 'plume2';
import {
  FormInput,
  Button,
  noop,
  Picker,
  FormSelect,
  ValidConst,
  Alert,
  FindArea
} from 'wmkit';
import { verify } from '@/social-c/store-bags-goods-detail/webapi';

const LongBlueButton = Button.LongBlue;
const SubmitButton = Button.LongBlue;
const TimerButton = Button.Timer;

const icon = require('../img/icon.png');
const failed = require('../img/failed.png');

@Relax
export default class Register extends Component<any, any> {
  static relaxProps = {
    toggleVisible: noop,
    initPage: noop,
    setFieldValue: noop,
    sendCode: noop,
    doRegister: noop,
    doPerfect: noop,
    toggleShowAgreement: noop,
    changeCustomerDetailField: noop,
    getArea: noop,
    registerAccount: 'registerAccount',
    registerPass: 'registerPass',
    registerCode: 'registerCode',
    showRegisterPass: 'showRegisterPass',
    //显示完善账户信息
    showImproveInfo: 'showImproveInfo',
    //会员基本信息
    customerDetail: 'customerDetail',
    //邀请人
    inviteeCustomerName: 'inviteeCustomerName',
    iepFlag: 'iepFlag',
    toggleEnterprise: noop,
    toggleShowPrivacyShow: noop,
  };

  // componentDidMount() {
  //   const { toggleShowPrivacyShow } = this.props.relaxProps;
  //   toggleShowPrivacyShow();
  // }

  render() {
    const {
      registerAccount,
      registerPass,
      registerCode,
      setFieldValue,
      showRegisterPass,
      sendCode,
      doRegister,
      showImproveInfo,
      customerDetail,
      inviteeCustomerName,
      changeCustomerDetailField,
      getArea,
      doPerfect,
      toggleShowAgreement,
      toggleShowPrivacyShow,
      iepFlag,
      toggleEnterprise
    } = this.props.relaxProps;
    const customerName = customerDetail.get('customerName');
    const customerAddress = customerDetail.get('customerAddress');
    const contactName = customerDetail.get('contactName');
    const contactPhone = customerDetail.get('contactPhone');
    let provinceId = customerDetail.get('provinceId');
    let cityId = customerDetail.get('cityId');
    let areaId = customerDetail.get('areaId');
    let area = provinceId
      ? cityId
        ? [provinceId.toString(), cityId.toString(), areaId.toString()]
        : [provinceId.toString()]
      : [];
    //拼接省市区名字
    let areaName = FindArea.addressInfo(provinceId, cityId, areaId);
    //对应显示的样式
    let textStyle = provinceId ? { color: '#333' } : {};
    const { toggleVisible, initPage } = this.props.relaxProps;
    return (
      <div className="registerContainer">
        <div className="loginContainer-header">
          <a
            href="javascript:void(0);"
            onClick={() => {
              toggleVisible({ visible: false });
              initPage();
            }}
            className="closeBox"
          >
            <i className="iconfont icon-guanbi" />
          </a>
          {iepFlag && (
            <a onClick={() => toggleEnterprise()} className="register-name">
              企业注册
            </a>
          )}
        </div>

        {!showImproveInfo ? (
          <div style={{ width: '90%', marginTop: '.3rem' }}>
            <h4 className="registerTitle">请使用有效手机号注册</h4>
            {inviteeCustomerName && (
              <p className="invite-text">邀请人：{inviteeCustomerName}</p>
            )}
            <div className="registerBox">
              <FormInput
                label=""
                type="tel"
                placeHolder="请输入您的手机号"
                pattern="[0-9]*"
                defaultValue={registerAccount}
                maxLength={11}
                onChange={(e) =>
                  setFieldValue({
                    field: 'registerAccount',
                    value: e.target.value
                  })
                }
              />
              <FormInput
                label=""
                type="number"
                placeHolder="请输入验证码"
                pattern="[0-9]*"
                defaultValue={registerCode}
                maxLength={6}
                onChange={(e) =>
                  setFieldValue({
                    field: 'registerCode',
                    value: e.target.value
                  })
                }
                other={
                  <TimerButton
                    text="发送验证码"
                    resetWhenError={true}
                    shouldStartCountDown={() => this._sendCode()}
                    onClick={() => sendCode(registerAccount)}
                    defaultStyle={{
                      color: ' #bd2d27',
                      border: 'none'
                    }}
                  />
                }
              />
              <div className="row form-item">
                <div className="eyes-box">
                  <input
                    placeholder="请输入6-16位字母或数字密码"
                    // type={showRegisterPass ? 'text' : 'password'}
                    value={registerPass}
                    pattern="/^[A-Za-z0-9]{6,16}$/"
                    className={`form-input ${
                      !showRegisterPass ? 'special' : ''
                    }`}
                    maxLength={16}
                    onChange={(e) =>
                      setFieldValue({
                        field: 'registerPass',
                        value: e.target.value
                      })
                    }
                  />
                  <img
                    onClick={() =>
                      setFieldValue({
                        field: 'showRegisterPass',
                        value: !showRegisterPass
                      })
                    }
                    src={
                      showRegisterPass
                        ? require('../img/5.png')
                        : require('../img/6.png')
                    }
                    style={{
                      width: '.5rem',
                      height: '.5rem',
                      marginLeft: '1.2rem'
                    }}
                  />
                </div>
              </div>
              <div className="registerTips">
                点击注册代表您已阅读并接受
                <a href="javascript:;" onClick={() => toggleShowAgreement()}>
                  <span>《会员注册协议》</span>
                </a>
                <a href="javascript:;" onClick={() => toggleShowPrivacyShow()}>
                  <span>《隐私政策》</span>
                </a>
              </div>
            </div>
            <div className="registerBtn">
              <LongBlueButton
                text="注册"
                onClick={() => doRegister()}
                defaultStyle={{
                  background: 'linear-gradient(to right, #bd2d27, #eb6d4e) ',
                  borderRadius: '.45rem',
                  height: '.8rem',
                  border: 'none'
                }}
              />
            </div>
          </div>
        ) : (
          <div className="improve-info">
            <div className="tip-box">
              <img src={icon} className="img" />
              <span className="text">
                您还需完善账户信息才可正常访问商品信息
              </span>
            </div>
            <div className="row form-item">
              <span className="form-text">
                <i>*</i>称呼/公司名称
              </span>
              <input
                placeholder="请填写您的称呼或您公司的名称"
                type="text"
                className="form-input"
                value={customerName}
                maxLength={15}
                onChange={(e) =>
                  changeCustomerDetailField({
                    field: 'customerName',
                    value: e.target.value
                  })
                }
              />
            </div>
            <Picker
              extra="所在地区"
              title="选择地址"
              format={(values) => {
                return values.join('/');
              }}
              value={area}
              className="addr-picker"
              onOk={(val) => getArea(val)}
            >
              <FormSelect
                labelName="选择地址"
                placeholder="请选择您所在的地区"
                textStyle={textStyle}
                selected={{ key: area, value: areaName }}
              />
            </Picker>
            <div className="row form-item">
              <span className="form-text">详细地址</span>
              <input
                placeholder="请填写您的详细地址"
                type="text"
                className="form-input"
                value={customerAddress}
                maxLength={60}
                onChange={(e) =>
                  changeCustomerDetailField({
                    field: 'customerAddress',
                    value: e.target.value
                  })
                }
              />
            </div>
            <div className="row form-item">
              <span className="form-text">
                <i>*</i>常用联系人
              </span>
              <input
                placeholder="请填写一位常用联系人"
                type="text"
                className="form-input"
                value={contactName}
                maxLength={15}
                onChange={(e) =>
                  changeCustomerDetailField({
                    field: 'contactName',
                    value: e.target.value
                  })
                }
              />
            </div>
            <div className="row form-item">
              <span className="form-text">
                <i>*</i>常用联系人手机号
              </span>
              <input
                placeholder="请填写联系人常用手机号"
                type="text"
                className="form-input"
                value={contactPhone}
                maxLength={11}
                onChange={(e) =>
                  changeCustomerDetailField({
                    field: 'contactPhone',
                    value: e.target.value
                  })
                }
              />
            </div>
            <div className="improve-btn">
              <SubmitButton text="提交" onClick={() => doPerfect()}>
                提交
              </SubmitButton>
            </div>
          </div>
        )}
      </div>
    );
  }

  _sendCode = () => {
    const { registerAccount } = this.props.relaxProps;
    const regex = ValidConst.phone;
    if (!registerAccount) {
      Alert({
        text: '手机号不能为空！'
      });
      return false;
    } else if (!regex.test(registerAccount)) {
      Alert({
        text: '手机号格式有误!'
      });
      return false;
    } else {
      return true;
    }
  };
}
