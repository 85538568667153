import React from 'react';

export default class CouponLabel extends React.PureComponent<any, any> {
  static defaultProps = {
    //券值
    text: ''
  };
  render() {
    const { text } = this.props;

    return (
      <div className="coupon-label">
        {/* <img src={require('./img/coupon-left.png')} /> */}
        <p>{text}</p>
        {/* <img src={require('./img/coupon-right.png')} className="img-right" /> */}
      </div>
    );
  }
}
