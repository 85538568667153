import { Fetch } from 'wmkit';
require('./wechat-init');
import { renderLoading } from 'wmkit/loading';
import parse from 'url-parse';

function wrapShareLink(link) {
  let { hash, query, origin, pathname } = parse(link, true);

  if (hash) {
    link = `${origin}${pathname}`;
    let search = Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&');
    link += `?${search}${hash}`;
  }
  return link;
}

/**
 * 签名缓存
 * @type {String}
 */
export const onMenuShare = (
  title,
  desc,
  link,
  imgUrl
): Promise<AsyncResult<any>> => {
  return wrapperPromise((resolve) => {
    let wx = (window as any).wx;
    // 分享设置
    let success = () => {
      return { res: true, err: null };
    };
    let cancle = () => {
      return { res: null, err: new Error('用户取消分享') };
    };

    link = wrapShareLink(link);

    // 朋友圈没有描述,所以标题放描述
    let timeLine = {
      title: desc,
      link,
      imgUrl,
      success,
      cancle
    };
    // 分享朋友
    let appMessage = {
      title,
      desc,
      link,
      imgUrl,
      // type: 'link',
      // dataUrl: '',
      success,
      cancle
    };
    // qq
    let shareQQ = {
      title,
      desc,
      link,
      imgUrl,
      success,
      cancle
    };

    wx.ready(() => {
      wx.onMenuShareAppMessage(appMessage);
      wx.onMenuShareTimeline(timeLine);
      wx.onMenuShareQQ(shareQQ);
    });
  });
};

export const etLocation = (): Promise<AsyncResult<any>> => {
  return wrapperPromise((resolve) => {
    let wx = (window as any).wx;
    // 获取坐标
    wx.ready(() => {
      wx.getLocation({
        type: 'wgs84',
        success: (res) => {
          let location = {
            latitude: res.latitude,
            longitude: res.longitude,
            speed: res.speed,
            accuracy: res.accuracy
          };
          return { res: location, err: null };
        },
        fail: (error) => {
          return { res: null, err: error };
        }
      });
    });
  });
};

/**
 * @param count 数量
 * @param sizeType 可以指定是原图还是压缩图，默认二者都有
 * @param sourceType 可以指定来源是相册还是相机，默认二者都有
 */
export const chooseAnduploadImage = (
  count = 1,
  sizeType = ['original', 'compressed'],
  sourceType = ['album', 'camera']
): Promise<AsyncResult<any>> => {
  return wrapperPromise((resolve) => {
    let wx = (window as any).wx;
    let chooseParams = {
      count: count,
      sizeType: sizeType,
      sourceType: sourceType,
      success: (res) => {
        let localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片

        let container = document.createElement('div');
        container.id = 'wx-upload-image-mask';
        const app = document.getElementById('app');
        (app.firstElementChild || app).appendChild(container);

        renderLoading({ /*tip: '图片上传中...', */ showMask: true }, container);

        wx.uploadImage({
          localId: localIds[0].toString(), // 需要上传的图片的本地ID，由chooseImage接口获得, 注意tostring
          isShowProgressTips: 0, // 默认为1，显示进度提示
          success: async (res) => {
            // 返回图片的服务器端ID
            resolve(await Fetch(`/common/image/upload/${res.serverId}`));
          }
        });
      },
      cancle: () => {
        resolve({ res: null, err: new Error('用户取消选择照片') });
      }
    };

    wx.ready(() => {
      wx.chooseImage(chooseParams);
    });
  });
};

/**
 * 包装微信返回的promise
 * @param callback
 */
const wrapperPromise = (callback: Function): Promise<AsyncResult<any>> => {
  return new Promise((resolve, reject) => {
    if (window['wechatConfig']) {
      callback(resolve);
    } else {
      resolve({ res: null, err: new Error('微信js未加载') });
    }
  });
};
