import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import AsyncRoute, {Loader} from './async-router'

type TFnComponent = (...params: Array<any>) => any;

export interface IRoute {
  path: string;
  strict?: boolean;
  exact?: boolean;
  component?: React.ComponentClass<any> | TFnComponent;
  asyncComponent?: Loader;
  routes?: Array<IRoute>;
  title?: string;
  hasBottom?: boolean;
  //不需要登录
  withoutLogin?: boolean;
  //根据开关,判断是否需要登陆
  openAccess?: boolean;
}

export default function routeWithSubRoutes(routes: Array<IRoute>, handlePathMatched: Function) {
  return <Switch>
    {
      routes.map((route, index) => {
        //dev check
        if (process.env.NODE_ENV != 'production') {
          if (route.component == undefined && route.asyncComponent == undefined) {
            throw new Error(`${route.path} can not find component or asyncComponent`)
          }
        }

        if (route.component) {
          return (
            <Route
              key={index}
              exact={route.exact}
              path={route.path}
              strict={route.strict}
              render={(props) => {
                return (<route.component {...props} subRoutes={route.routes}/>)
              }
              }
              handlePathMatched={handlePathMatched}
            />
          )
        } else {
          return (
            <AsyncRoute
              key={index}
              exact={route.exact}
              strict={route.strict}
              path={route.path}
              subRoutes={route.routes}
              load={route.asyncComponent}
              title={route.title}
              hasBottom={route.hasBottom}
              handlePathMatched={handlePathMatched}
              withoutLogin={route.withoutLogin}
              openAccess={route.openAccess}
            />
          )
        }
      })}
  </Switch>
}