import React from 'react';
import { Alert } from 'wmkit';

export interface NumberInputProps {
  //组件禁用
  disableNumberInput?: boolean;
  //禁用 +
  disableAdd?: boolean;
  //禁用 -
  disableSubtraction?: boolean;
  //值
  value?: number;
  //组件错误
  error?: string;
  min?: number;
  max?: number;
  // 值改变时回调，值发生变化立即触发
  onChange?: Function;
  // 值改变时回调，延时触发，200ms内多次连续操作只触发一次
  onDelayChange?: Function;
  // 点击加号回调，两个参数：1.加号是否已禁用 2.this.state.value+1
  onAddClick?: Function;
  // 点击事件结束之后调用
  onAfterClick?: Function;
}

export default class NumberInput extends React.PureComponent<
  NumberInputProps,
  any
> {
  input;
  // 连续点击处理定时器
  timer;

  static defaultProps = {
    value: 0,
    min: 0
  };

  constructor(props: NumberInputProps) {
    super(props);
  }

  state = {
    //是否禁用该组件
    disableNumberInput: this.props.disableNumberInput,
    //是否禁用左边的按钮
    disableAdd: this.props.disableAdd,
    disableSubtraction: this.props.disableSubtraction,
    value: this.props.value,
    // 计数器，用户直接输入触发setState时+1
    counter: 0 // 为了解决输入框连续输入字母后只有第一次重置为min问题：当value已经等于min时，再输入字母，正则校验失败并且通过setState
    // 设置value=min，这时state没有改变，不会触发re-render，输入框就会一直保留刚才输入的字母
  };

  componentWillMount() {
    if (this.state.value <= this.props.min || this.state.disableNumberInput) {
      this.setState({ disableSubtraction: true });
    }
    if (this.state.value >= this.props.max || this.state.disableNumberInput) {
      this.setState({ disableAdd: true });
    }
  }

  /**
   * 会出现父组件主动重置value的业务，这里重新设置一下。如果还有其他props会改变，需要在这里赋值
   * @param {Readonly<P>} nextProps
   * @param nextState
   */
  componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
    if (nextProps.disableNumberInput) {
      this.setState({ disableAdd: true });
      this.setState({ disableSubtraction: true });
      this.setState({ disableNumberInput: true });
    } else {
      this.setState({
        disableAdd: false,
        disableSubtraction: false,
        disableNumberInput: false
      });
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  componentDidUpdate() {
    this.setState({
      disableSubtraction:
        this.props.disableSubtraction || this.state.value <= this.props.min
    });
    this.setState({
      disableAdd: this.props.disableAdd || this.state.value >= this.props.max
    });
  }

  render() {
    const {
      disableAdd,
      disableSubtraction,
      disableNumberInput,
      value
    } = this.state;

    return (
      <div onClick={(e) => e.stopPropagation()}>
        <div className="num-input-box">
          <div
            className="circle"
            style={{
              borderColor:
                disableSubtraction || disableNumberInput ? '#ececec' : '#000'
            }}
            onClick={() => this._handleSubtract()}
          >
            <i
              className="iconfont icon-jian11"
              style={{
                ...styles.symbol,
                color:
                  disableSubtraction || disableNumberInput ? '#bcbcbc' : '#333'
              }}
            />
          </div>
          <input
            ref={(input) => (this.input = input)}
            type="tel"
            size={3}
            value={value}
            className={`num-input ${
              disableNumberInput ? 'disabled-input' : null
            }`}
            disabled={disableNumberInput}
            onChange={(e) => this._handleChange(e)}
            onBlur={(e) => this._handleBlur(e)}
          />
          <div
            className="circle"
            style={{
              borderColor: disableAdd || disableNumberInput ? '#ececec' : '#000'
            }}
            onClick={() => this._handleAdd()}
          >
            <i
              className="iconfont icon-add11"
              style={{
                ...styles.symbol,
                color: disableAdd || disableNumberInput ? '#bcbcbc' : '#333'
              }}
            />
          </div>
        </div>
        <p className="num-tips">{this.props.error}</p>
      </div>
    );
  }

  _handleAdd() {
    const { disableAdd, disableNumberInput } = this.state;
    const onAddClick = this.props.onAddClick;

    // 是否不能点击
    const disabled = disableAdd || disableNumberInput;
    if (onAddClick) {
      onAddClick(disabled, this.state.value + 1);
    }
    if (disabled) {
      return;
    }

    // this._handleTimeout();

    const onDelayChange = this.props.onDelayChange;
    if (onDelayChange) {
      onDelayChange(this.state.value + 1);
    }

    // const onChange = this.props.onChange;
    // this.setState({ value: this.state.value + 1 }, () => {
    //   if (onChange) {
    //     onChange(this.state.value);
    //   }
    // });
  }

  _handleSubtract() {
    const onChange = this.props.onChange;
    if (this.state.disableSubtraction || this.state.disableNumberInput) {
      return;
    }

    const onDelayChange = this.props.onDelayChange;
    if (onDelayChange) {
      onDelayChange(this.state.value - 1);
    }
    // this._handleTimeout();

    // this.setState({ value: this.state.value - 1 }, () => {
    //   if (onChange) {
    //     onChange(this.state.value);
    //   }
    // });
  }

  _handleChange = (e) => {
    const onChange = this.props.onChange;
    const reg = /^[0-9]*$/;

    // 如果不是合法的数字或者数字小于min，重置为min
    let value =
      !reg.test(e.target.value) || Number(e.target.value) < 0
        ? 0
        : Number(e.target.value);

    // this._handleTimeout();
    // counter+1，避免value相同时不re-render
    const counter = this.state.counter + 1;
    //最大值最小值处理
    if (value > this.props.max) {
      value = this.props.max;
    }
    const onDelayChange = this.props.onDelayChange;
    if (onDelayChange) {
      onDelayChange(value);
    }
    this.setState({ value: value, counter: counter });

    // this.setState({ value: value, counter: counter }, () => {
    //   if (value < this.props.min) {
    //     return;
    //   }
    //   if (onChange) {
    //     onChange(this.state.value);
    //   }
    // });
  };

  /**
   * 输入框离开数字恢复最小值
   * @param e
   * @private
   */
  _handleBlur = (e) => {
    const onChange = this.props.onChange;
    const onDelayChange = this.props.onDelayChange;
    if (Number(e.target.value) < this.props.min) {
      const counter = this.state.counter + 1;
      this.setState({ value: this.props.min, counter: counter }, () => {
        if (onChange) {
          onChange(this.state.value);
        }

        if (onDelayChange) {
          onDelayChange(this.state.value);
        }
      });
    }
  };

  /**
   * 延时处理
   * @private
   */
  _handleTimeout = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.timer = setTimeout(() => {
      const onDelayChange = this.props.onDelayChange;
      if (onDelayChange) {
        onDelayChange(this.state.value);
      }
    }, 200);
  };
}

const styles = {
  symbol: {
    fontSize: 12
  } as any
};
