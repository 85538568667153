import GoodsNum from './goods-num';
import StoreClose from './store-close';
import BStoreClose from './b-store-close';
import MarketingLabel from './marketing-label';
import NewLayer from './new-layer';
import RegisterCoupon from './coupon-model/register-coupon';
import GrouponListItem from './groupon/groupon-list-item';
import GrouponSearch from './groupon/groupon-search';
import * as evaluateWebapi from './evaluateIsShow/webapi';
import {
  delPurchase,
  mergePurchase,
  postPurchase,
  putPurchase,
  putSkuMarketingCache
} from './purchase-front';
import { getShareUserId } from './share-operate/share-operate';
//分享弹出
import ShareModal from './share-modal';
//图文分享
import GraphicShare from './graphic-share';

export {
  GoodsNum,
  StoreClose,
  BStoreClose,
  MarketingLabel,
  postPurchase,
  putPurchase,
  delPurchase,
  putSkuMarketingCache,
  mergePurchase,
  RegisterCoupon,
  NewLayer,
  ShareModal,
  GraphicShare,
  evaluateWebapi,
  getShareUserId,
  GrouponListItem,
  GrouponSearch
};
