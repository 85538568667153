import React from 'react';
import { Progress } from 'antd-mobile';

/**
 * 进度条
 */
export default class ProgressBar extends React.PureComponent<any, any> {
  props: {
    text?: string;
    style?: {};
    barStyle?: {};
    position?: string;
    percent?: number;
  };

  // static defaultProps = {
  //   text: '',
  //   style: {},
  //   barStyle: {},
  //   position: '',
  //   percent: ''
  // };

  render() {
    const { barStyle, style, percent } = this.props;
    return (
      <Progress
        position="normal"
        percent={percent}
        style={{ ...styles.pStyle, ...style }}
        barStyle={{ ...styles.pBarStyle, ...barStyle }}
      />
    );
  }
}

const styles = {
  pStyle: {
    backgroundColor: 'rgba(29, 5, 0, 0.5)',
    borderRadius: 3
  },
  pBarStyle: {
    border: '3px solid #ffea17',
    borderRadius: 3
  }
};
