import React from 'react';
import './css/style.css';

/**
 * 公共评星
 */
export default class Star extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      //接到页面传过来的值
      num: this.props.star,
      //根据页面当中的星星的数量来设置默认值
      arr: [1, 2, 3, 4, 5],
      style: this.props.style
    };
  }
  static defaultProps = {
    star: 0
  };

  render() {
    let { arr, style } = this.state;
    return (
      <span>
        {arr.map((item, index) => {
          return (
            <span
              key={index}
              className={
                'iconfont icon-xing star ' +
                (item > this.props.star ? '' : 'star-light')
              }
              style={style}
              onClick={() =>
                this.props.handleClick && this.props.handleClick(index + 1)
              }
            />
          );
        })}
      </span>
    );
  }
}
