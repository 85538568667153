import {cache} from '../../config';

/**
 * 获取分享人id
 */
const getShareUserId = () => {
  let shareUserId = sessionStorage.getItem('shareUserId');
  if (shareUserId) {
    return shareUserId;
  } else {
    return sessionStorage.getItem(cache.INVITEE_ID);
  }
};

export { getShareUserId };
