import { Actor, Action, IMap } from 'plume2';
import { IList } from 'typings/globalType';
import { fromJS, Map } from 'immutable';
import { StateType } from 'rmc-tabs/lib/Tabs';
export default class RegisterActor extends Actor {
  defaultState() {
    return {
      //注册账号
      registerAccount: '',
      //注册密码
      registerPass: '',
      //注册验证码
      registerCode: '',
      //注册时是否显示密码
      showRegisterPass: false,
      //显示注册协议
      showAgreement: false,
      inviteCode: '',
      // 邀请人
      inviteeCustomerName: '',
      //显示企业用户注册协议
      showEnterpriseAgreement: false,
      privacyShow: false,//隐私政策显示隐藏

      images:[],

      enterpriseInfoVO:{
        enterpriseId:'',
        enterpriseName:'',
        socialCreditCode:'',
        businessNatureType:'',
        businessIndustryType:'',
        businessLicenseUrl:'',
        inviteCode:'',
        customerId:''
      },
      registerLimitType: 0, //注册限制，0：不限，1：邀请码
      openFlag:0, //是否开启社交分销,0:否，1：是
    };
  }
  // 邀请人
  @Action('change:inviteeCustomerName')
  changeInviteeCustomerName(state, inviteeCustomerName) {
    return state.set('inviteeCustomerName', inviteeCustomerName);
  }

  @Action('field:value')
  setFieldValue(state, { field, value }) {
    return state.set(field, value);
  }

  @Action('loginModal:toggleShowAgreement')
  toggleShowAgreement(state) {
    return state.set('showAgreement', !state.get('showAgreement'));
  }

  @Action('modal:clear:register')
  clearRegister(state) {
    return state
      .set('registerAccount', '')
      .set('registerPass', '')
      .set('registerCode', '')
      .set('showRegisterPass', false)
      .set('showAgreement', false);
  }

  @Action('loginModal:toggleShowEnterpriseAgreement')
  toggleShowEnterpriseAgreement(state) {
    return state.set('showEnterpriseAgreement', !state.get('showEnterpriseAgreement'));
  }

  @Action('field:setEnterpriseFieldValue')
  setEnterpriseFieldValue(state, { field, value }) {
    return state.setIn(['enterpriseInfoVO', field], value);
  }

  /**
   * 上传附件
   */
  @Action('enterprise: apply : addImage')
  addImage(state: IMap, image) {
    state = state.setIn(['enterpriseInfoVO', 'businessLicenseUrl'], image.image);
    return state.set('images', state.get('images').push(fromJS(image)));
  }

  /**
   * 删除附件
   */
  @Action('enterprise: apply : removeImage')
  removeImage(state: IMap, index) {
    state = state.setIn(['enterpriseInfoVO', 'businessLicenseUrl'], '');
    return state.set('images', state.get('images').remove(index));
  }

  /**
   * 注册限制，0：不限，1：邀请码
   * @param state
   * @param reisterLimitType
   */
  @Action('set:registerLimitType')
  setRegisterLimitType(state, data) {
    return state.set('registerLimitType', fromJS(data).get('registerLimitType')).set('openFlag', fromJS(data).get('openFlag'));
  }


  /**
   * @Description:隐私政策显示还是隐藏
   * @author malaian
   * @date 2020-11-05 14:36
   */
  @Action('loginModal:toggleShowPrivacyShow')
  toggleShowPrivacyShow(state) {
    return state.set('privacyShow', !state.get('privacyShow'));
  }

}
