import React, { Component } from 'react';
import { Picker } from 'antd-mobile';

export default class BusinessEmployeeNumPicker extends Component<any, any> {
  render() {
    return (
      <Picker
        data={[
          { value: 0, label: '1-49' },
          { value: 1, label: '50-99' },
          { value: 2, label: '100-499' },
          { value: 3, label: '500-999' },
          { value: 4, label: '1000以上' }
          ]}
        cols={1}
        {...this.props as any}
      >
        <Wrapper content={this.props.children as any}>
        </Wrapper>
      </ Picker>
    );
  }
}


class Wrapper extends React.Component<any, any> {

  render() {
    // 需要调用onClick方法弹出地址组件
    const { onClick, content } = this.props as any;
    return (
      <div onClick={onClick || undefined}>
        {content}
      </div>
    );
  }
}
