import React from 'react'
import {noop} from 'wmkit'

/**
 * 公共Radio单选按钮
 */
export default class Radio extends React.PureComponent<any, any> {

  static defaultProps = {
    //选中值
    checked: 0,
    //选择方法
    onCheck: noop,
    //样式
    style: {},
    //选项数据
    data: [],
    //按钮在文本前
    beforeTxt: false,
  }

  render() {
    const { checked , style, data, beforeTxt} = this.props
    return (
      <div>
        {
          data.map(val => {
           return (
             <div style={{...styles.checkBox, ...style}} onClick={() => this._onCheck(val.id)}>
               {!beforeTxt ? <div>{val.name}</div> : ''}
             <div className={`check ${ checked == val.id ? 'checked' : '' }`}>
               <i className="iconfont icon-check"></i>
             </div>
               {beforeTxt ? <div>{val.name}</div> : ''}
             </div>
           )
          })
        }
      </div>
    )
  }

  _onCheck = (k) => {
    this.props.onCheck(k)
  }
}


const styles = {
  checkBox:{
    display: 'flex'
  },
}
