import React from 'react';
import { Link } from 'react-router-dom';
import { msg } from 'plume2';
import * as webapi from './webapi';
import { url, config } from 'config';
import { WMkit, history } from 'wmkit';

export default class Footer extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      //sku种类数量
      purchaseNum: 0,
      // 是否开启社交分销 0：关闭，1：开启
      openFlag: 0,
      // 当前登录人是否是分销员小B
      isDistributor: false
    };
  }

  componentWillMount() {
    msg.on('purchaseNum', this._fetchPurChaseNum);
    // if (window.token) {
    msg.on('userLoginRefresh', this._fetchDistributorInfo);
    // }
  }

  componentDidMount() {
    // this._fetchPurChaseNum();

    // if (window.token) {
    // 查询分销员信息，是否是分销员
    // this._fetchDistributorInfo();
    // }
    // 分销总开关
    // this._fetchQueryOpenFlag();
  }

  // componentWillReceiveProps(nextProps) {
  //   // 查询分销员信息，是否是分销员
  //   this._fetchDistributorInfo();

  //   // 分销总开关
  //   this._fetchQueryOpenFlag();
  // }

  componentWillUnmount() {
    msg.off('purchaseNum', this._fetchPurChaseNum);
    msg.off('userLoginRefresh', this._fetchDistributorInfo);
  }

  props: {
    path: string;
  };

  render() {
    //分销渠道
    const isShop = WMkit.isShop();
    // 当前登录人是否是分销员小B
    const isDistributor = this.state.isDistributor;

    const socialPath = '/shop-index-c/' + WMkit.inviteeId();

    let __Url = url;

    return (
      <div>
        <div className="footer" style={{ backgroundColor: '#fff' }}>
          <ul>
            {isShop ? (
              <li
                className={
                  this.props.path.includes(__Url.STORE_SOCIAL_SITE)
                    ? 'cur'
                    : null
                }
              >
                <Link to={socialPath}>
                  <img
                    src={require(this.props.path.includes(
                      __Url.STORE_SOCIAL_SITE
                    )
                      ? './img/24.png'
                      : './img/19.png')}
                    className={'iconImg'}
                  />
                  <span>精选</span>
                </Link>
              </li>
            ) : (
                <li className={this.props.path == __Url.MAIN ? 'cur' : null}>
                  <Link to={__Url.MAIN}>
                    <img
                      src={require(this.props.path == __Url.MAIN
                        ? './img/24.png'
                        : './img/19.png')}
                      className={'iconImg'}
                    />
                    <span>首页</span>
                  </Link>
                </li>
              )}

            {!isShop ? (
              this.state.openFlag && isDistributor ? (
                <li
                  className={
                    this.props.path == __Url.MATERIAL_CIRCLE ? 'cur' : null
                  }
                >
                  <Link to={__Url.MATERIAL_CIRCLE}>
                    <img
                      src={require(this.props.path == __Url.MATERIAL_CIRCLE
                        ? './img/material-red.png'
                        : './img/material.png')}
                      className={'iconImg'}
                    />
                    <span>发现</span>
                  </Link>
                </li>
              ) : (
                  <li
                    className={this.props.path == __Url.GOODS_LIST ? 'cur' : null}
                  >
                    <Link to={__Url.GOODS_LIST}>
                      <img
                        src={require(this.props.path == __Url.GOODS_LIST
                          ? './img/25.png'
                          : './img/20.png')}
                        className={'iconImg'}
                      />
                      <span>商品分类</span>
                    </Link>
                  </li>
                )
            ) : null}

            {WMkit.isLogin() ? (
              !isShop ? (
                this.state.openFlag ? (
                  isDistributor ? (
                    <li
                      className={
                        this.props.path == __Url.DISTRIBUTION_CENTER
                          ? 'cur'
                          : null
                      }
                    >
                      <a
                        onClick={() => {
                          history.push(__Url.FRIEND_CLASS);
                        }}
                      >
                        <img
                          src={require(this.props.path ==
                            __Url.DISTRIBUTION_CENTER
                            ? './img/30.png'
                            : './img/29.png')}
                          className={'iconImg'}
                        />
                        <span>分销中心</span>
                      </a>
                    </li>
                  ) : (
                      <li
                        className={
                          this.props.path == __Url.FRIEND_CLASS ? 'cur' : null
                        }
                      >
                        <a
                          onClick={() => {
                            history.push(__Url.FRIEND_CLASS);
                          }}
                        >
                          <img
                            src={require(this.props.path == __Url.FRIEND_GROUP || this.props.path == __Url.FRIEND_CLASS
                              ? './img/32.png'
                              : './img/31.png')}
                            className={'iconImg'}
                          />
                          <span>医鹿学院</span>
                        </a>
                      </li>
                    )
                ) : <li
                  className={
                    this.props.path == __Url.FRIEND_CLASS ? 'cur' : null
                  }
                >
                    <a
                      onClick={() => {
                        history.push(__Url.FRIEND_CLASS);
                      }}
                    >
                      <img
                        src={require(this.props.path == __Url.FRIEND_GROUP || this.props.path == __Url.FRIEND_CLASS
                          ? './img/32.png'
                          : './img/31.png')}
                        className={'iconImg'}
                      />
                      <span>医鹿学院</span>
                    </a>
                  </li>
              ) : <li
                className={
                  this.props.path == __Url.FRIEND_CLASS ? 'cur' : null
                }
              >
                  <a
                    onClick={() => {
                      history.push(__Url.FRIEND_CLASS);
                    }}
                  >
                    <img
                      src={require(this.props.path == __Url.FRIEND_GROUP || this.props.path == __Url.FRIEND_CLASS
                        ? './img/32.png'
                        : './img/31.png')}
                      className={'iconImg'}
                    />
                    <span>医鹿学院</span>
                  </a>
                </li>
            ) : (
                <li
                  className={
                    this.props.path == __Url.FRIEND_CLASS ? 'cur' : null
                  }
                >
                  <a
                    onClick={() => {
                      history.push(__Url.FRIEND_CLASS);
                    }}
                  >
                    <img
                      src={require(this.props.path == __Url.FRIEND_GROUP || this.props.path == __Url.FRIEND_CLASS
                        ? './img/32.png'
                        : './img/31.png')}
                      className={'iconImg'}
                    />
                    <span>医鹿学院</span>
                  </a>
                </li>
              )}

            <li
              className={this.props.path == __Url.PURCHASE_ORDER ? 'cur' : null}
            >
              <Link to={__Url.PURCHASE_ORDER}>
                <div className="footer-tag">
                  <img
                    src={require(this.props.path == __Url.PURCHASE_ORDER
                      ? './img/26.png'
                      : './img/21.png')}
                    className={'iconImg'}
                  />
                  {this.state.purchaseNum > 0 && (
                    <span className="tag">{this.state.purchaseNum}</span>
                  )}
                </div>
                <span>购物车</span>
              </Link>
            </li>
            <li className={this.props.path == __Url.USER_CENTER ? 'cur' : null}>
              <Link to={url.USER_CENTER}>
                <img
                  src={require(this.props.path == __Url.USER_CENTER
                    ? './img/28.png'
                    : './img/23.png')}
                  className={'iconImg'}
                />
                <span>我的</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  //查询购物车数量
  _fetchPurChaseNum = async () => {
    if (WMkit.isLoginOrNotOpen()) {
      const { context, code } = await webapi.fetchPurchaseCount();
      if (code == config.SUCCESS_CODE) {
        this.setState({ purchaseNum: context });
      }
    } else {
      const purCache =
        JSON.parse(localStorage.getItem(WMkit.purchaseCache())) || [];
      this.setState({ purchaseNum: purCache.length });
    }
  };

  /**
   * 查询平台端-社交分销总开关状态
   * 是否开启社交分销 0：关闭，1：开启
   */
  _fetchQueryOpenFlag = async () => {
    const { context, code } = await webapi.qeuryOpenFlag();
    if (code == config.SUCCESS_CODE) {
      this.setState({ openFlag: context });
    }
  };

  /**
   * 查询分销员信息，是否是分销员
   * @returns {Promise<void>}
   * @private
   */
  _fetchDistributorInfo = async () => {
    if (window.token) {
      // 先查询分销员信息
      const { context, code } = await webapi.fetchDistributorInfo();
      if (code == config.SUCCESS_CODE) {
        if (context) {
          let distributionCustomerVO = (context as any).distributionCustomerVO;
          //设置分销员的信息
          if (distributionCustomerVO) {
            this.setState({
              isDistributor: (distributionCustomerVO as any).distributorFlag
            });
          }
        }
      }
    }
  };
}
