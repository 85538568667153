import { Store, msg } from 'plume2';

import {
  _,
  Alert,
  history,
  storage,
  ValidConst,
  VAS,
  WMkit,
  wxAuth
} from 'wmkit';
import { fromJS } from 'immutable';
import { getShareUserId, mergePurchase } from 'biz';
import { cache, config, Const, VASConst } from 'config';
import LoginActor from './actor/login-actor';
import RegisterActor from './actor/register-actor';
import InfoActor from './actor/info-actor';
import AgreeActor from './actor/agree-actor';
import EnterpriseActor from './actor/enterprise-actor';
import EnterpriseAgreeActor from './actor/enterprise-agree-actor';
import * as webapi from './webapi';
import { isNullOrUndefined } from 'util';
import { message } from 'antd';
import moment from 'moment';

/**校验名称和联系人*/
function _testName(name: string) {
  if (name == '') {
    Alert({
      text: '请填写名称！'
    });
    return false;
  } else if (name.length < 2 || name.length > 15) {
    Alert({
      text: '名称为2-15个字符！'
    });
    return false;
  }
  return true;
}

function _testEnterpriseName(name: string) {
  if (name.trim() == '') {
    Alert({
      text: '请填写公司名称！',
      time: 1000
    });
    return false;
  } else if (name.trim().length < 2 || name.trim().length > 60) {
    Alert({
      text: '公司名称为2-60个字符！',
      time: 1000
    });
    return false;
  } else if (!ValidConst.companyName.test(name)) {
    Alert({
      text: '公司名称仅支持中文、英文、数字及“_”、“-”、()、（）',
      time: 1000
    });
    return false;
  }
  return true;
}

function _testSocialCreditCode(code: string) {
  if (code.trim() == '') {
    Alert({
      text: '请填写统一社会信用代码！',
      time: 1000
    });
    return false;
  } else if (code.trim().length < 8 || code.trim().length > 30) {
    Alert({
      text: '统一社会信用代码为8-30个字符！',
      time: 1000
    });
    return false;
  } else if (!ValidConst.enterpriseSocialCreditCode.test(code)) {
    Alert({
      text: '统一社会信用代码仅支持大写英文和数字！',
      time: 1000
    });
    return false;
  }
  return true;
}

function _testBusinessLicenseUrl(url: string) {
  if (url.trim() == '') {
    Alert({
      text: '请上传营业执照！',
      time: 1000
    });
    return false;
  }
  return true;
}

function _testInviteInfo(
  inviteCode: string,
  registerLimitType: number,
  openFlag: number
) {
  //分销开启、设置邀请码必填时
  if (openFlag && registerLimitType == 1) {
    if (inviteCode.trim() == '') {
      Alert({
        text: '请填写邀请码！',
        time: 1000
      });
      return false;
    }
    if (inviteCode.trim().length > 8) {
      Alert({
        text: '请填写正确的邀请码！',
        time: 1000
      });
      return false;
    }
    return true;
  }

  if (openFlag && registerLimitType != 1) {
    if (inviteCode.trim().length > 8) {
      Alert({
        text: '请填写正确的邀请码！',
        time: 1000
      });
      return false;
    }
    return true;
  }
  return false;
}

function _testBusinessNatureType(businessNatureType: string) {
  if (businessNatureType == '') {
    Alert({
      text: '请选择公司性质！'
    });
    return false;
  }
  return true;
}

function _testContact(contact: string) {
  if (contact == '') {
    Alert({
      text: '请填写常用联系人！'
    });
    return false;
  } else if (contact.length < 2 || contact.length > 15) {
    Alert({
      text: '联系人名称为2-15个字符！'
    });
    return false;
  }
  return true;
}

/**校验地址详情*/
function _testAddress(address: string) {
  if (address == '') {
    Alert({
      text: '请填写详细地址！'
    });
    return false;
  } else if (address.length < 5 || address.length > 60) {
    Alert({
      text: '地址长度为5-60个字符！'
    });
    return false;
  }
  return true;
}

/**校验手机号码*/
function _testTel(tel: string) {
  const regex = ValidConst.phone;
  if (tel == '') {
    Alert({
      text: '请填写联系人手机号！'
    });
    return false;
  } else if (!regex.test(tel)) {
    Alert({
      text: '无效的手机号！'
    });
    return false;
  } else {
    return true;
  }
}

export default class AppStore extends Store {
  bindActor() {
    return [
      new LoginActor(),
      new RegisterActor(),
      new InfoActor(),
      new AgreeActor(),
      new EnterpriseActor(),
      new EnterpriseAgreeActor()
    ];
  }

  constructor(props) {
    super(props);
    (window as any)._store = this;
  }

  init = async (cancelCallBack) => {
    // 初始化基本信息和微信授权登录开关
    const res = await Promise.all([
      webapi.fetchBaseConfig(),
      webapi.fetchWxLoginStatus(),
      await VAS.checkIepAuth()
    ]);
    if (
      res[0].code == config.SUCCESS_CODE &&
      res[1].code == config.SUCCESS_CODE
    ) {
      this.dispatch(
        'login:init',
        (res[0].context as any).pcLogo
          ? JSON.parse((res[0].context as any).pcLogo)[0]
          : { url: '' }
      );
      //取消不登录的回调
      this.dispatch('login:cancelCallBack', cancelCallBack);
      // 注册协议
      this.dispatch('agree:registerContent', res[0].context as any);
      //清除注册弹框信息
      this.dispatch('modal:clear:register');
      //清除完善账户弹框信息
      this.dispatch('modal:clear:info');
      this.dispatch('login:wxFlag', res[1].context);
      //设置企业购信息
      this.dispatch('enterprise:iepFlag', res[2]);
      //初始化企业注册协议
      if (res[2]) {
        this.dispatch(
          'agree:registerEnterpriseContent',
          fromJS(await VAS.fetchIepInfo()).getIn([
            'iepInfo',
            'enterpriseCustomerRegisterContent'
          ])
        );
      }

      const { code, context } = await webapi.getRegisterLimitType();
      if (code == config.SUCCESS_CODE) {
        this.dispatch('set:registerLimitType', context);
      }

      if (!isNullOrUndefined(WMkit.inviteeId())) {
        // 查询邀请人姓名
        const { code, context } = await webapi.getCustomerInfo(
          WMkit.inviteeId()
        );
        if (code == config.SUCCESS_CODE) {
          if (context) {
            //获取邀请人信息
            this.dispatch(
              'change:inviteeCustomerName',
              (context as any).customerName
            );
          }
        }
      }
    }

    if (__DEV__ || !_.isWeixin()) {
      return;
    }
  };

  showPass = () => {
    const showpass = this.state().get('isShowpwd');
    this.dispatch('login:showPass', showpass);
  };

  doLogin = async () => {
    // true：账号密码登录  false：验证码登录
    const isALogin = this.state().get('isALogin');
    let result = null;
    if (isALogin) {
      /**
       * 获取用户名和密码，并去除所有空格*/
      const account = this.state()
        .get('account')
        .trim()
        .replace(/\s/g, '');
      const password = this.state()
        .get('password')
        .trim()
        .replace(/\s/g, '');
      if (WMkit.testAccount(account) && WMkit.testPass(password)) {
        const base64 = new WMkit.Base64();
        const res = await webapi.login(
          base64.urlEncode(account),
          base64.urlEncode(password)
        );
        result = res;
      } else {
        return;
      }
    } else {
      /**
       * 获取用户名和验证码，并去除所有空格*/
      const account = this.state()
        .get('account')
        .trim()
        .replace(/\s/g, '');
      const verificationCode = this.state()
        .get('verificationCode')
        .trim()
        .replace(/\s/g, '');
      if (
        WMkit.testTel(account) &&
        WMkit.testVerificationCode(verificationCode)
      ) {
        const res = await webapi.loginWithVerificationCode(
          account,
          verificationCode
        );
        result = res;
      } else {
        return;
      }
    }
    /**
     * 登录成功时*/
    if ((result as any).code == config.SUCCESS_CODE) {
      // 登陆成功后，设置资质未提醒状态
      sessionStorage.setItem(cache.QUALIFICATION_REMIND, '0');
      const { context } = result;
      sessionStorage.setItem(cache.IS_MASTER, context.isMaster);
      this.switchLogin(context);
    }
    //密码输错5次，按钮设灰色
    else if ((result as any).code == 'K-010004') {
      this.transaction(() => {
        this.dispatch('login:buttonstate');
        this.dispatch('login:buttonvalue', result.message);
      });
      Alert({
        text: result.message
      });
      return;
    } else {
      /**
       * 登录失败，提示各类出错信息*/
      Alert({
        text: result.message
      });
      return false;
    }
  };

  setAccount = (account) => {
    this.transaction(() => {
      //登录按钮恢复可点击状态
      this.dispatch('login:enableButton');
      this.dispatch('login:account', account);
      this.dispatch('login:accountChange', this.state().get('accountChange'));
    });
  };

  setPressError = (errorPress: boolean) => {
    this.dispatch('login:errorPress', errorPress);
  };

  setPassword = (pass) => {
    this.dispatch('login:password', pass);
  };

  /**
   * 输入验证码
   * @param code
   */
  setVerificationCode = (code) => {
    this.dispatch('login:verificationCode', code);
  };

  /**忘记密码*/
  forgetPass = async () => {
    history.push('/user-safe-password');
  };

  /*切换登录方式*/
  loginChange = () => {
    this.dispatch('login:loginChange');
  };

  /**
   * 微信登录授权
   */
  weChatQuickLogin = async () => {
    const url = location.href;
    //弹框隐藏(因为重定向以后页面不刷新)
    this.dispatch('loginModal:toggleVisible', {
      visible: false,
      callBack: this.state().get('callBack')
    });
    wxAuth.getAuth(url.split('?')[0], 'login');
  };

  /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
  /** ** ** ** ** ** ** ** ** ** ** ** ** * 验证码登录 * ** ** ** ** ** ** ** ** ** ** ** ** **/
  /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

  /**
   * 发送验证码给手机号码
   * @returns {Promise<Result<ReturnResult>>}
   */
  sendCode = (mobile) => {
    //登录发送验证码还是注册发送验证码
    const showLogin = this.state().get('showLogin');
    return webapi.sendCode(mobile, showLogin).then((res) => {
      if (res.code === config.SUCCESS_CODE) {
        Alert({
          text: '验证码已发送，请注意查收！',
          time: 1000
        });
      } else {
        Alert({
          text: res.message,
          time: 1000
        });
        return Promise.reject(res.message);
      }
    });
  };

  /**
   * 发送验证码给手机号码
   * @returns {Promise<Result<ReturnResult>>}
   */
  sendEnterpriseRegisterCode = (mobile) => {
    //登录发送验证码还是注册发送验证码
    return webapi
      .sendEnterpriseRegisterCode(mobile, '990090-998', '')
      .then((res) => {
        if (res.code === config.SUCCESS_CODE) {
          Alert({
            text: '验证码已发送，请注意查收！',
            time: 1000
          });
        } else {
          Alert({
            text: res.message,
            time: 1000
          });
          return Promise.reject(res.message);
        }
      });
  };

  //弹框显示隐藏
  toggleVisible = ({ visible, callBack }) => {
    this.dispatch('loginModal:toggleVisible', { visible, callBack });
  };

  /**
   * 根据登录后的返回参数跳转到相应页面
   * @param context
   */
  switchLogin = (context: any) => {
    //企业会员登录
    if (context.customerDetail && context.enterpriseCheckState != 0) {
      this.switchEnterpriseLogin(context);
    } else {
      //普通会员登录
      this.switchCommonLogin(context);
    }
  };

  switchCommonLogin = (context: any) => {
    switch (context.checkState) {
      /**审核中*/
      case 0:
        WMkit.clearLoginCache();
        //在完善信息开关打开的时候，且注册了以后没有完善账户信息
        if (!context.isLoginFlag && !context.customerDetail.customerName) {
          //存储customerId
          this.dispatch('change:customerDetailField', {
            field: 'customerId',
            value: (context as any).customerId
          });
          //关闭登录弹框
          this.toggleLogin();
          //显示完善信息的弹框
          this.dispatch('show:showImproveInfo');
        } else {
          //将审核中的账户信息存入本地缓存
          storage('local').set(
            cache.PENDING_AND_REFUSED,
            JSON.stringify(context)
          );
          //关闭弹框，调到完善账户信息
          this.dispatch('loginModal:toggleVisible', {
            visible: false,
            callBack: null
          });
          history.push(`/improve-information/${context.customerId}`);
        }
        break;
      /**审核通过，成功登录*/
      case 1:
        // a.设置登陆后token以及登陆信息缓存
        window.token = context.token;
        localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
        sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
        WMkit.setIsDistributor();
        // b.登陆成功,需要合并登录前和登陆后的购物车,传入回调函数
        mergePurchase(this.state().get('callBack'));
        // c.登陆成功,关闭弹框
        Alert({ text: '登录成功' });
        // 登录成功，发送消息，查询分销员信息 footer/index.tsx
        msg.emit('userLoginRefresh');
        this.dispatch('loginModal:toggleVisible', {
          visible: false,
          callBack: null
        });
        _.showRegisterModel((context as any).couponResponse, true);
        break;
      /**审核未通过*/
      default:
        WMkit.clearLoginCache();
        this.dispatch('loginModal:toggleVisible', {
          visible: false,
          callBack: null
        });
        //将审核中的账户信息存入本地缓存
        storage('local').set(
          cache.PENDING_AND_REFUSED,
          JSON.stringify(context)
        );
        history.push(`/improve-information/${(context as any).customerId}`);
    }
  };

  /**
   * 登录时初始化企业购会员信息
   * @param context
   */
  switchEnterpriseLogin = (context: any) => {
    this.transaction(() => {
      // this.dispatch('change:customerDetailField', { field: 'customerId', value: context.customerId});
      this.dispatch('field:loginActorValue', {
        field: 'showLogin',
        value: false
      });
      this.dispatch('field:loginActorValue', {
        field: 'showEnterprise',
        value: true
      });
      this.dispatch('field:loginActorValue', {
        field: 'showImproveInfo',
        value: false
      });
      this.dispatch('field:loginActorValue', {
        field: 'showEnterpriseMoreInfo',
        value: true
      });
      this.dispatch('field:loginActorValue', {
        field: 'enterpriseCheckState',
        value: context.enterpriseCheckState
      });
      //赋值会员信息
      this.dispatch('field:value', {
        field: 'enterpriseInfoVO',
        value: fromJS(context.enterpriseInfoVO)
      });
      this.dispatch('enterprise: apply : removeImage', 0);
      this.dispatch('enterprise: apply : addImage', {
        image: context.enterpriseInfoVO.businessLicenseUrl
      });
      this.dispatch('field:setEnterpriseFieldValue', {
        field: 'inviteCode',
        value: context.inviteCode
      });
    });

    this.initInviteInfo();

    switch (context.enterpriseCheckState) {
      //待审核中 tip初始化  页面置灰   customer信息赋值  注册按钮隐藏
      case 1:
        this.dispatch('field:loginActorValue', {
          field: 'enterpriseCheckTip',
          value: ''
        });
        this.dispatch('field:loginActorValue', {
          field: 'enterpriseButtonFlag',
          value: false
        });
        break;
      //审核不通过 走重新提交流程  tip初始化  customer赋值 reason 赋值
      case 3:
        this.dispatch('field:loginActorValue', {
          field: 'enterpriseCheckTip',
          value: context.enterpriseCheckReason
        });
        this.dispatch('field:loginActorValue', {
          field: 'enterpriseButtonFlag',
          value: true
        });
        break;
      case 2:
        // a.设置登陆后token以及登陆信息缓存
        window.token = context.token;
        localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
        sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
        WMkit.setIsDistributor();
        // b.登陆成功,需要合并登录前和登陆后的购物车,传入回调函数
        mergePurchase(this.state().get('callBack'));
        // c.登陆成功,关闭弹框
        Alert({ text: '登录成功' });
        // 登录成功，发送消息，查询分销员信息 footer/index.tsx
        msg.emit('userLoginRefresh');
        this.dispatch('loginModal:toggleVisible', {
          visible: false,
          callBack: null
        });
        _.showRegisterModel((context as any).couponResponse, true);
        break;
    }
  };

  //赋值邀请码
  initInviteInfo = async () => {
    const { code, context } = await webapi.getRegisterLimitType();
    if (code == config.SUCCESS_CODE) {
      this.dispatch('set:registerLimitType', context);
    }
  };

  toggleLogin = () => {
    this.dispatch('loginModal:toggleLogin');
  };

  toggleEnterprise = () => {
    this.dispatch('modal:clear:register');
    this.dispatch('loginModal:toggleEnterprise');
  };

  //还原设置
  initPage = () => {
    this.dispatch('loginModal:initPage');
  };

  //改变指定的state值
  setFieldValue = ({ field, value }) => {
    this.dispatch('field:value', { field, value });
  };

  //注册
  doRegister = async () => {
    //参数准备
    const mobile = this.state().get('registerAccount');
    const sendCode = this.state().get('registerCode');
    const password = this.state().get('registerPass');
    const employeeId = this.state().get('employeeId');
    const shareUserId = getShareUserId();
    const inviteeId = WMkit.inviteeId();

    if (
      WMkit.testTel(mobile) &&
      WMkit.testPass(password) &&
      WMkit.testVerificationCode(sendCode)
    ) {
      const fromPage = 1;

      const { code, message } = await webapi.checkRegister(
        mobile,
        sendCode,
        fromPage
      );
      if (code == config.SUCCESS_CODE) {
        const registerInfo = {
          customerAccount: mobile,
          customerPassword: password,
          verifyCode: sendCode,
          inviteeId: inviteeId,
          shareUserId: shareUserId,
          fromPage: fromPage //0:注册页面，1：注册弹窗
        };
        sessionStorage.setItem(
          cache.REGISTER_INFO,
          JSON.stringify(registerInfo)
        );
        this.dispatch('loginModal:toggleVisible', {
          visible: false,
          callBack: null
        });
        // history.push(`/distribution-register`);
        const info: any = JSON.parse(
          sessionStorage.getItem(cache.REGISTER_INFO)
        );
        const page = info.fromPage;
        if (page) {
          this.registerModal(info);
        } else {
          this.register(info);
        }
        // return true;
      } else {
        Alert({
          text: message
        });
      }
    } else {
      return false;
    }
  };

  /**
   * 注册页面-注册
   * @param registerInfo
   * @returns {Promise<void>}
   */
  register = async (registerInfo) => {
    const { code, message, context } = await webapi.register(
      registerInfo.customerAccount,
      registerInfo.customerPassword,
      registerInfo.verifyCode,
      registerInfo.patchca,
      registerInfo.uuid,
      registerInfo.employeeId,
      registerInfo.shareUserId,
      this.state().get('inviteCode')
    );
    if (code == config.SUCCESS_CODE) {
      WMkit.clearLoginCache();
      //清除本地缓存的审核未通过的或者正在审核中的账户信息
      localStorage.removeItem(cache.PENDING_AND_REFUSED);
      sessionStorage.removeItem(cache.REGISTER_INFO);
      //是否直接可以登录 0 否 1 是
      if (!(context as any).isLoginFlag) {
        Alert({
          text: '注册成功，请完善账户信息'
        });
        //提示完善账户信息
        history.push(`/improve-information/${(context as any).customerId}`);
      } else {
        Alert({
          text: '注册成功'
        });
        //直接跳转到主页
        localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
        sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
        // 注册成功后，设置资质未提醒状态
        sessionStorage.setItem(cache.QUALIFICATION_REMIND, '0');
        // c.登陆成功,关闭弹框
        await this.delay(800);
        Alert({
          text: '登录成功'
        });
        setTimeout(() => {
          history.push('/');
          _.showRegisterModel((context as any).couponResponse, true);
        }, 1000);
      }
    } else {
      Alert({
        text: message
      });
    }
  };

  /**
   * 弹窗注册-注册
   * @param registerInfo
   * @returns {Promise<void>}
   */
  registerModal = async (registerInfo) => {
    const { code, message, context } = await webapi.registerModal(
      registerInfo.customerAccount,
      registerInfo.customerPassword,
      registerInfo.verifyCode,
      registerInfo.inviteeId,
      registerInfo.shareUserId,
      this.state().get('inviteCode')
    );
    if (code == config.SUCCESS_CODE) {
      //清除本地缓存的审核未通过的或者正在审核中的账户信息
      localStorage.removeItem(cache.PENDING_AND_REFUSED);
      sessionStorage.removeItem(cache.REGISTER_INFO);
      //是否直接可以登录 0 否 1 是
      if (!(context as any).isLoginFlag) {
        Alert({
          text: '注册成功，请完善账户信息'
        });
        //存储customerId
        // this.dispatch('change:customerDetailField', {
        //   field: 'customerId',
        //   value: (context as any).customerId
        // });
        // this.dispatch('show:showImproveInfo');
        history.push(`/improve-information/${(context as any).customerId}`);
      } else {
        Alert({ text: '注册成功' });
        //存储token
        window.token = (context as any).token;
        localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
        sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
        // 登录成功，发送消息，查询分销员信息 footer/index.tsx
        msg.emit('userLoginRefresh');
        // b.登陆成功,需要合并登录前和登陆后的购物车,传入回调函数
        mergePurchase(this.state().get('callBack'));
        // 注册成功后，设置资质未提醒状态
        sessionStorage.setItem(cache.QUALIFICATION_REMIND, '0');
        // c.登陆成功,关闭弹框
        await this.delay(800);
        Alert({
          text: '登录成功'
        });

        /* this.dispatch('loginModal:toggleVisible', {
          visible: false,
          callBack: null
        });*/
        setTimeout(() => {
          _.showRegisterModel((context as any).couponResponse, true);
        }, 1000);
      }
    } else {
      Alert({
        text: message
      });
    }
  };

  //注册
  doEnterpriseRegister = async () => {
    //这个地方customerId置为空
    // this.dispatch('change:customerDetailField', { field: 'customerId', value: ''});
    //参数准备
    const mobile = this.state().get('registerAccount');
    const sendCode = this.state().get('registerCode');
    const password = this.state().get('registerPass');
    const employeeId = this.state().get('employeeId');
    const shareUserId = getShareUserId();
    const inviteeId = WMkit.inviteeId();
    if (
      WMkit.testTel(mobile) &&
      WMkit.testPass(password) &&
      WMkit.testVerificationCode(sendCode)
    ) {
      const fromPage = 1;
      //调用首次注册接口
      const { code, message } = await webapi.doRegisterEnterprise({
        firstRegisterFlag: true,
        customerAccount: mobile,
        customerPassword: password,
        verifyCode: sendCode
      });

      if (code == config.SUCCESS_CODE) {
        const registerInfo = {
          customerAccount: mobile,
          customerPassword: password,
          verifyCode: sendCode,
          inviteeId: inviteeId,
          shareUserId: shareUserId,
          fromPage: fromPage //0:注册页面，1：注册弹窗
        };

        sessionStorage.setItem(
          cache.REGISTER_INFO,
          JSON.stringify(registerInfo)
        );
        this.dispatch('loginModal:showEnterpriseMoreInfo');
        return true;
      } else {
        Alert({
          text: message
        });
      }
    } else {
      return false;
    }
  };

  //查询会员基本信息
  queryCustomerDetal = async (id: string) => {
    const res = storage('local').get(cache.PENDING_AND_REFUSED);
    //如果存在，更改用户ID的状态值
    this.dispatch('field:value', { field: 'customerId', value: id });
    if (res) {
      const customer = JSON.parse(res).customerDetail;
      this.transaction(() => {
        this.dispatch('detail:customer', customer);
        this.dispatch('detail:checked', JSON.parse(res).checkState);
      });
    }
  };

  //填写会员信息
  changeCustomerDetailField = ({ field, value }) => {
    this.dispatch('change:customerDetailField', { field, value });
  };

  getArea = (value: number[]) => {
    this.dispatch('detail:area', value);
  };

  /**完善账户信息*/
  doPerfect = async () => {
    const customer = this.state()
      .get('customerDetail')
      .toJS();
    /**先校验必填项*/
    if (
      _testName(customer.customerName) &&
      _testContact(customer.contactName) &&
      _testTel(customer.contactPhone)
    ) {
      //不为空，且不是不限
      if (customer.provinceId) {
        if (!_testAddress(customer.customerAddress)) {
          return false;
        }
      }
      if (customer.customerAddress != '') {
        //未选择省份或者选择不限时，都视为未选择
        if (customer.provinceId == '' || customer.provinceId == null) {
          Alert({
            text: '请选择所在地区！'
          });
          return false;
        }
      }
      const { code, message, context } = await webapi.doPerfect(customer);
      if (code == config.SUCCESS_CODE) {
        //清除缓存
        localStorage.removeItem(cache.PENDING_AND_REFUSED);
        //是否直接可以登录 0 否 1 是
        if (!(context as any).isLoginFlag) {
          Alert({
            text: '提交成功，将会尽快给您审核！'
          });
          //弹框关闭
          this.dispatch('loginModal:toggleVisible', {
            visible: false,
            callBack: null
          });
          _.showRegisterModel((context as any).couponResponse, false);
        } else {
          // a.设置登陆后token以及登陆信息缓存
          window.token = (context as any).token;
          localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
          sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
          // 登录成功，发送消息，查询分销员信息 footer/index.tsx
          msg.emit('userLoginRefresh');
          // b.登陆成功,需要合并登录前和登陆后的购物车,传入回调函数
          mergePurchase(this.state().get('callBack'));
          // c.登陆成功,关闭弹框
          Alert({ text: '登录成功' });
          //关闭弹框
          this.dispatch('loginModal:toggleVisible', {
            visible: false,
            callBack: null
          });
          _.showRegisterModel((context as any).couponResponse, true);
        }
      } else {
        Alert({
          text: message
        });
        return false;
      }
    }
  };

  /**完善企业用户信息*/
  doEnterpriseSubmit = async () => {
    const enterpriseInfoVO = this.state()
      .get('enterpriseInfoVO')
      .toJS();
    let registerInfo: any = JSON.parse(
      sessionStorage.getItem(cache.REGISTER_INFO)
    );

    if (registerInfo == null) {
      registerInfo = {
        customerAccount: '1',
        customerPassword: '1',
        verifyCode: '1'
      };
    }

    const registerLimitType = this.state().get('registerLimitType');
    const openFlag = this.state().get('openFlag');
    /**先校验必填项*/
    if (
      _testBusinessNatureType(enterpriseInfoVO.businessNatureType) &&
      _testEnterpriseName(enterpriseInfoVO.enterpriseName) &&
      _testSocialCreditCode(enterpriseInfoVO.socialCreditCode) &&
      _testBusinessLicenseUrl(enterpriseInfoVO.businessLicenseUrl) &&
      _testInviteInfo(enterpriseInfoVO.inviteCode, registerLimitType, openFlag)
    ) {
      const { code, message, context } = await webapi.doRegisterEnterprise({
        customerId: enterpriseInfoVO.customerId,
        firstRegisterFlag: false,
        customerAccount: registerInfo.customerAccount,
        customerPassword: registerInfo.customerPassword,
        verifyCode: registerInfo.verifyCode,
        inviteCode: enterpriseInfoVO.inviteCode,
        businessNatureType: enterpriseInfoVO.businessNatureType,
        enterpriseName: enterpriseInfoVO.enterpriseName,
        socialCreditCode: enterpriseInfoVO.socialCreditCode,
        businessLicenseUrl: enterpriseInfoVO.businessLicenseUrl
      });
      if (code == config.SUCCESS_CODE) {
        //清除缓存
        localStorage.removeItem(cache.PENDING_AND_REFUSED);
        //清空企业用户注册信息
        this.removeEnterpriseInfo();
        //是否直接可以登录 0 否 1 是
        if (
          fromJS(await VAS.fetchIepInfo()).getIn([
            'iepInfo',
            'enterpriseCustomerAuditFlag'
          ]) == 1
        ) {
          Alert({
            text: '提交成功，将会尽快给您审核！'
          });
          //弹框关闭
          this.dispatch('loginModal:toggleVisible', {
            visible: false,
            callBack: null
          });
          _.showRegisterModel((context as any).couponResponse, false);
        } else {
          // a.设置登陆后token以及登陆信息缓存
          window.token = (context as any).token;
          localStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
          sessionStorage.setItem(cache.LOGIN_DATA, JSON.stringify(context));
          // 登录成功，发送消息，查询分销员信息 footer/index.tsx
          msg.emit('userLoginRefresh');
          // b.登陆成功,需要合并登录前和登陆后的购物车,传入回调函数
          mergePurchase(this.state().get('callBack'));
          // c.登陆成功,关闭弹框
          Alert({ text: '登录成功' });
          //关闭弹框
          this.dispatch('loginModal:toggleVisible', {
            visible: false,
            callBack: null
          });
          history.push('/main');
          _.showRegisterModel((context as any).couponResponse, true);
        }
      } else {
        Alert({
          text: message
        });
        return false;
      }
    }
  };

  //清空企业用户注册信息
  removeEnterpriseInfo = () => {
    this.dispatch('field:value', {
      field: 'enterpriseInfoVO',
      value: fromJS({
        enterpriseId: '',
        enterpriseName: '',
        socialCreditCode: '',
        businessNatureType: '',
        businessIndustryType: '',
        businessLicenseUrl: '',
        inviteCode: '',
        customerId: ''
      })
    });
    this.dispatch('enterprise: apply : removeImage', 0);
  };

  //切换注册协议登录显示
  toggleShowAgreement = () => {
    this.dispatch('loginModal:toggleShowAgreement');
  };

  //切换企业用户注册协议登录显示
  toggleShowEnterpriseAgreement = () => {
    this.dispatch('loginModal:toggleShowEnterpriseAgreement');
  };

  //切换
  toggleBackEnterprise = () => {
    this.dispatch('modal:clear:register');
    this.dispatch('loginModal:showEnterpriseMoreInfo');
  };

  //切换
  toggleBackLogin = () => {
    this.transaction(() => {
      this.dispatch('modal:clear:register');
      this.dispatch('modal:clear:info');
      this.dispatch('field:loginActorValue', {
        field: 'showLogin',
        value: true
      });
      this.dispatch('field:loginActorValue', {
        field: 'showEnterprise',
        value: false
      });
      this.dispatch('field:loginActorValue', {
        field: 'showImproveInfo',
        value: false
      });
      this.dispatch('field:loginActorValue', {
        field: 'showEnterpriseMoreInfo',
        value: false
      });
      this.dispatch('field:loginActorValue', {
        field: 'enterpriseCheckState',
        value: ''
      });
      //赋值会员信息
      this.dispatch('field:value', {
        field: 'enterpriseInfoVO',
        value: fromJS({
          enterpriseId: '',
          enterpriseName: '',
          socialCreditCode: '',
          businessNatureType: '',
          businessIndustryType: '',
          businessLicenseUrl: '',
          inviteCode: '',
          customerId: ''
        })
      });
      this.dispatch('enterprise: apply : removeImage', 0);
      this.dispatch('field:loginActorValue', {
        field: 'enterpriseCheckTip',
        value: ''
      });
      this.dispatch('field:loginActorValue', {
        field: 'enterpriseButtonFlag',
        value: true
      });
    });
  };

  //改变指定的state值
  setEnterpriseFieldValue = ({ field, value }) => {
    if (field == 'businessNatureType') {
      value = value[0];
    }
    this.dispatch('field:setEnterpriseFieldValue', { field, value });
  };

  /**
   * 上传附件
   */
  addImage = (image) => {
    this.dispatch('enterprise: apply : addImage', image);
  };

  /**
   * 删除附件
   */
  removeImage = (index) => {
    this.dispatch('enterprise: apply : removeImage', index);
  };
  delay = (timeout) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, timeout);
    });
  };


  //打开隐私政策页面
  toggleShowPrivacyShow = () => {
    this.dispatch('loginModal:toggleShowPrivacyShow');
  };

}
