import React from 'react';

import { Check, Fetch, Alert } from 'wmkit';
import { cache, config } from 'config';
import './css/style.css';
import { IMap } from 'plume2';
import wx from 'weixin-js-sdk';

export default class ShareModal extends React.PureComponent<any, any> {
  props: {
    visible: boolean;
    changeVisible: Function;
    //传递过来的sku信息
    goodsInfo?: IMap;
    //分享类型，0:普通分享，1：店铺内，2：店铺外
    shareType?: Number;
  };

  constructor(props) {
    super(props);
    this.state = {
      //分享此商品后添加至我的店铺，默认勾选
      checked: true
    };
  }

  render() {
    const { visible, changeVisible, goodsInfo } = this.props;
    return (
      visible && (
        <div>
          <div className="poper">
            <div
              className="mask"
              onClick={() => {
                changeVisible(false);
              }}
            />
            <div className="poper-content sharingLayer">
              <div className="title b-1px-b">
                <p className="price">
                  赚{goodsInfo.get('distributionCommission')}元
                </p>
                <p className="infoText">
                  好友通过你分享的链接购买商此商品，你就能赚{goodsInfo.get(
                    'distributionCommission'
                  )}元的利润~
                </p>
              </div>
              <div
                className="icon-content"
                style={{ justifyContent: 'center' }}
              >
                <div
                  className="icon"
                  onClick={() => {
                    this.jumpSmallProgram();
                  }}
                >
                  <img src={require('./img/graphic.png')} alt="" />
                  <p>图文分享</p>
                </div>
              </div>
              <div className="checkText">
                <Check
                  checked={this.state.checked}
                  onCheck={() =>
                    this.setState({
                      checked: !this.state.checked
                    })
                  }
                />
                <p>&nbsp;&nbsp;分享此商品后添加至我的店铺</p>
              </div>
              <div
                className="share-btn-pro b-1px-t"
                onClick={() => {
                  changeVisible(false);
                }}
              >
                取消分享
              </div>
            </div>
          </div>
        </div>
      )
    );
  }

  /**
   * 跳转到微信小程序图文分享
   */
  jumpSmallProgram = async () => {
    const { goodsInfo } = this.props;
    //先去添加商品到店铺
    if (this.state.checked) {
      Fetch('/distributor-goods/add', {
        method: 'POST',
        body: JSON.stringify({
          goodsInfoId: goodsInfo.get('goodsInfoId'),
          goodsId: goodsInfo.get('goodsId'),
          storeId: goodsInfo.get('storeId')
        })
      }).then((res) => {
        if (res.code != config.SUCCESS_CODE) {
          Alert({
            text: res.message
          });
        }
      });
    }
    //拼装小程序图文分享需要的参数
    let params = {
      goodsInfoName: goodsInfo.get('goodsInfoName'),
      goodsInfoImg: goodsInfo.get('goodsInfoImg'),
      specText: goodsInfo.get('specText'),
      marketPrice: goodsInfo.get('marketPrice'),
      lineShowPrice: goodsInfo.get('lineShowPrice'),
      skuId: goodsInfo.get('goodsInfoId'),
      //分享类型，0：普通分享，1：分享赚(店铺内) ,2：分享赚（店铺外）3:邀新，4：分享店铺
      shareType: this.props.shareType,
      inviteeId:
        JSON.parse(localStorage.getItem(cache.LOGIN_DATA)).customerId || '',
      token: window.token,
      goodsId: goodsInfo.get('goodsId')
    };
    // //这边获取下商品的数据等
    wx.miniProgram.navigateTo({
      url: `../canvas/canvas?data=${JSON.stringify(params)}`
    });
  };
}
